import styled from "styled-components";
import { isEmpty } from "lodash";
import { useParams, useSearchParams } from "react-router-dom";

import { parseJson } from "utils/common";
import LayoutApp from "components/LayoutApp";
import ChatViewNew from "components/ChatViewNew";
import { useEffect } from "react";
import { getPipelineConfig } from "api/backend/fileSystemEndpoints";
import { useState } from "react";
import LayoutAppConfigurable from "components/LayoutAppConfigurable";

const Container = styled.div`
  /* position: relative;
  width: 800px;
  padding-top: 0px;
  height: calc(100vh - 40px);
  transition: width 0.2s; */

  position: relative;
  margin: 0 auto;
  padding-top: 0px;
  height: calc(100vh - 40px);
  transition: width 0.2s;
`;

const AppChatPageNew = () => {
  const [searchParams] = useSearchParams();
  const { pipelineConfigId } = useParams();
  const [pipelineConfig, setPipelineConfig] = useState(null);

  const openSidebarType = parseJson(searchParams?.get("openSidebarType"));
  const isSideBarOpen = !!openSidebarType && !isEmpty(openSidebarType);

  useEffect(() => {
    doPopulatePipelineConfig();
  }, [pipelineConfigId]);

  const doPopulatePipelineConfig = async () => {
    const { data } = await getPipelineConfig(pipelineConfigId);
    setPipelineConfig(data);
  };

  let LayoutComponent = LayoutApp;
  if (window?.location?.pathname.includes("/apps-new")) {
    LayoutComponent = LayoutAppConfigurable;
  }

  return (
    <LayoutComponent
      bgColor="linear-gradient(180deg, #F3F5F7 0%, #FDFDFD 100%);"
      topBarBgColor="#f3f5f7"
    >
      <Container isSideBarOpen={isSideBarOpen}>
        <ChatViewNew
          genContext="app_chat"
          pipelineConfig={pipelineConfig}
          pipelineConfigId={pipelineConfigId}
        />
      </Container>
    </LayoutComponent>
  );
};

export default AppChatPageNew;
