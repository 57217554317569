import { Gap } from "components/Layout";
import ButtonWord from "components/ui/ButtonWord";
import { PlusIcon, TrashIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import { cloneDeep, set } from "lodash";
import { INITIAL_LIMIT, INITIAL_SORT_FILTER } from "pages/apps/records";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { parseJson } from "utils/common";

const ModalContent = styled.div`
  position: relative;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  width: 600px;
  height: 400px;
  overflow: auto;
`;

const Title = styled.div`
  font-weight: 500;
`;

const Filters = styled.div`
  overflow: auto;
  height: 200px;
  display: grid;
  gap: 0px;
  align-content: start;
`;

const FilterContainer = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  justify-items: start;
  align-items: center;
  gap: 10px;
  padding: 8px;
  border-bottom: 1px solid #d8d8d8;
`;

const StyledPlusIcon = styled(PlusIcon)`
  width: 14px;
  height: 14px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const StyledTrashIcon = styled(TrashIcon)`
  width: 14px;
  height: 14px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const TopRightBtn = styled(ButtonWord)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const BottomContainer = styled.div`
  position: absolute;
  bottom: 20px;
  padding-top: 10px;
  width: 560px;
  background-color: white;
  border-top: 1px solid #d8d8d8;
  display: grid;
  justify-content: end;
`;

const Trigger = styled.div``;

const StyledSelect = styled.select`
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px;
  font-weight: 500;
`;

const StyledInput = styled.input`
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px 8px;
  font-weight: 500;
`;

const NewRecordButtonConfigModal = ({
  config = {},
  onClickApply = (newText, newPosition) => {},
  trigger = <ButtonWord>New Record Btn Config</ButtonWord>,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [buttonText, setButtonText] = useState(config?.newButtonText || "New");

  const onClickApplyButton = () => {
    onClickApply(buttonText);
    setIsOpen(false);
  };

  return (
    <>
      <Trigger style={{ justifySelf: "end" }} onClick={() => setIsOpen(true)}>
        {trigger}
      </Trigger>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Title>Configure "New Record" button</Title>
          <Gap />
          <StyledInput
            value={buttonText}
            onChange={e => setButtonText(e.target.value)}
          />

          <BottomContainer>
            <ButtonWord isPrimary onClick={onClickApplyButton}>
              Apply
            </ButtonWord>
          </BottomContainer>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewRecordButtonConfigModal;
