import { OpenWith } from "@material-ui/icons";
import { CrossIcon } from "components/ui/Icons";
import { cloneDeep, isEmpty, isNil, set } from "lodash";
import { Children, useRef, useEffect, useState } from "react";
import styled from "styled-components";

import { isTruthy } from "utils/common";

const SQUARE_SIZE = 20;
const NUM_ROWS = 100;
const NUM_COLS = 100;

const INITIAL_COLS = 2;
const INITIAL_W = 24;
const INITIAL_H = 14;

const isRectOverEmptyCell = (rect = { x: null, y: null }, layout = {}) => {
  if (isNil(rect?.x) || isNil(rect?.y)) {
    return false;
  }

  const isRectOverId = id => {
    const { x, y, w, h } = layout[id];
    const isOverX = rect?.x >= x - 1 && rect?.x <= x + w;
    const isOverY = rect?.y >= y - 1 && rect?.y <= y + h;
    return isOverX && isOverY;
  };

  const ids = Object.keys(layout);
  const isOverACell = ids.some(isRectOverId);

  return !isOverACell;
};

const placeItems = (keys, layout) => {
  const newLayout = cloneDeep(layout);

  let x = 1;
  let y = 1;
  let w = INITIAL_W;
  let h = INITIAL_H;

  keys.filter(isTruthy).forEach(key => {
    newLayout[key] = { x, y, w, h };
    x += w + 1;
    if (x >= INITIAL_COLS * w) {
      x = 1;
      y += h + 1;
    }
  });

  return newLayout;
};

const ensureMargins = (layout = {}) => {
  const newLayout = {};
  Object.entries(layout).forEach(([id, box]) => {
    const { x, y, w, h } = box;
    newLayout[id] = {
      x: Math.max(1, x),
      y: Math.max(0, y),
      w,
      h,
    };
  });

  return newLayout;
};

const getNearestCoords = (e, containerRef, gridSF = 1) => {
  const containerRect = containerRef.current.getBoundingClientRect();
  const { scrollLeft, scrollTop } = containerRef.current;

  let offsetX = e.clientX - containerRect.x + scrollLeft;
  let offsetY = e.clientY - containerRect.y - SQUARE_SIZE * 0.5 + scrollTop;
  offsetX = offsetX / gridSF;
  offsetY = offsetY / gridSF;

  const nearestX = Math.round(offsetX / SQUARE_SIZE) || 0;
  const nearestY = Math.round(offsetY / SQUARE_SIZE) || 0;

  return { nearestX, nearestY };
};

const Container = styled.div`
  white-space: pre-wrap;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 1;

  ::-webkit-scrollbar {
    display: none;
  }

  background-size: ${SQUARE_SIZE}px ${SQUARE_SIZE}px;
  background-image: linear-gradient(
      to right,
      ${props => (props.isBgVisible ? "#eaeaea" : "transparent")} 1px,
      transparent 1px
    ),
    linear-gradient(
      to bottom,
      ${props => (props.isBgVisible ? "#eaeaea" : "transparent")} 1px,
      transparent 1px
    );
`;

const MoveHandle = styled.div`
  position: absolute;
  bottom: -${SQUARE_SIZE}px;
  right: ${0}px;
  width: ${SQUARE_SIZE}px;
  height: ${SQUARE_SIZE}px;
  cursor: move;
  opacity: 0;
  transition: opacity 0.2s;
  border: 1px dashed #b8b8b8;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: #d8d8d8;
  }

  svg {
    color: #b8b8b8;
  }

  ${props => props.isEditingDisabled && "display: none;"}
`;

const ResizeHandle = styled.div`
  position: absolute;
  bottom: -${SQUARE_SIZE * 0.5}px;
  right: -${SQUARE_SIZE * 0.5}px;
  width: ${SQUARE_SIZE * 0.5}px;
  height: ${SQUARE_SIZE * 0.5}px;
  cursor: nwse-resize;
  opacity: 0;
  transition: opacity 0.2s;
  border: 1px dashed #b8b8b8;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: #d8d8d8;
  }

  svg {
    color: #b8b8b8;
  }

  ${props => props.isEditingDisabled && "display: none;"}
`;

const HoverRect = styled.div`
  position: absolute;
  background-color: #1e47ff5c;
  width: ${SQUARE_SIZE}px;
  height: ${SQUARE_SIZE}px;
  opacity: 0.2;
  transition: opacity 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MessageHoverRect = styled.div`
  position: absolute;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${SQUARE_SIZE}px;
  background-color: black;
  color: white;
  padding: 4px;
`;

const DraggableItem = styled.div`
  position: absolute;
  z-index: 1;
  :hover {
    ${MoveHandle} {
      opacity: 1;
    }
    ${ResizeHandle} {
      opacity: 1;
    }
  }
`;

const ItemContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px dashed ${props => (props.isDragging ? "#b8b8b8" : "transparent")};
  :hover {
    border: 1px dashed #e3e3e3;
    ${props => props.isEditingDisabled && "border: 1px solid transparent;"}
  }
`;

const CrossHandle = styled.div`
  position: absolute;
  top: 0;
  right: -${SQUARE_SIZE}px;
  padding: 4px;
  border-radius: 50%;
  :hover {
    background-color: #d8d8d8;
  }
  ${props => props.isEditingDisabled && "display: none;"}
`;

const isLayoutBroken = layoutItem => {
  return [layoutItem?.x, layoutItem?.y, layoutItem?.w, layoutItem?.h].some(
    isNil
  );
};

const GridDraggable = ({
  gridSF = 1,
  initialLayout = {},
  onDragEnd = newLayout => {},
  onClickEmptyCell = ({ x, y }, e) => {},
  children = [],
  className = "",
  style = {},
  isEditingDisabled = false,
  areItemsRemovable = false,
  onDeleteKey = () => {},
  tooltipMsg = "Click to add plot here",
}) => {
  const containerRef = useRef(null);
  const [layout, setLayout] = useState(initialLayout);
  const [isMouseOverComponent, setIsMouseOverComponent] = useState(false);
  const [hoverRect, setHoverRect] = useState({ x: -1, y: -1 });
  const [scroll, setScroll] = useState({ scrollLeft: 0, scrollTop: 0 });

  const keys = Children?.map(children, child => child?.key);

  useEffect(() => {
    if (isEmpty(initialLayout)) {
      const newLayout = placeItems(keys, {});
      setLayout(ensureMargins(newLayout));
      return;
    }

    setLayout(ensureMargins(initialLayout));
  }, [JSON.stringify(initialLayout), JSON.stringify(keys)]);

  const [keyBeingMoved, setKeyBeingMoved] = useState("");
  const [keyBeingResized, setKeyBeingResized] = useState("");

  const isDragging = keyBeingMoved || keyBeingResized;

  return (
    <Container
      className={className}
      onWheel={() => {
        const { scrollLeft, scrollTop } = containerRef.current;
        setScroll({ scrollLeft, scrollTop });
      }}
      isBgVisible={!isEditingDisabled}
      style={{
        ...style,
        backgroundPosition: `-${scroll.scrollLeft}px -${scroll.scrollTop}px`,
      }}
      ref={containerRef}
      onDragOver={e => {
        e.preventDefault();
        const { nearestX, nearestY } = getNearestCoords(
          e,
          containerRef,
          gridSF
        );
        const newLayout = cloneDeep(layout);

        if (keyBeingMoved) {
          if (isLayoutBroken(newLayout?.[keyBeingMoved])) {
            newLayout[keyBeingMoved] = {
              x: 0,
              y: 0,
              w: INITIAL_W,
              h: INITIAL_H,
            };
          }

          const newX = nearestX - newLayout?.[keyBeingMoved]?.w;
          const newY = nearestY - newLayout?.[keyBeingMoved]?.h;
          set(newLayout, `${keyBeingMoved}.x`, newX);
          set(newLayout, `${keyBeingMoved}.y`, newY);
        }

        if (keyBeingResized) {
          newLayout[keyBeingResized].w =
            nearestX - newLayout[keyBeingResized].x;
          newLayout[keyBeingResized].h =
            nearestY - newLayout[keyBeingResized].y;
        }

        setLayout(newLayout);
      }}
      onDragEnd={() => {
        onDragEnd(ensureMargins(layout));
        setKeyBeingMoved("");
        setKeyBeingResized("");
      }}
      onMouseMove={e => {
        if (isEditingDisabled) {
          return;
        }

        const { nearestX, nearestY } = getNearestCoords(
          e,
          containerRef,
          gridSF
        );
        setHoverRect({
          x: nearestX,
          y: nearestY,
        });
      }}
      onClick={e => {
        if (isEditingDisabled || isMouseOverComponent) {
          return;
        }
        onClickEmptyCell(hoverRect, e);
      }}
    >
      <HoverRect
        style={{
          top: hoverRect?.y * SQUARE_SIZE,
          left: hoverRect?.x * SQUARE_SIZE,
          // opacity: isRectOverEmptyCell(hoverRect, layout) ? 0.2 : 0,
          display: isEditingDisabled || isMouseOverComponent ? "none" : "flex",
        }}
      >
        +
      </HoverRect>
      <MessageHoverRect
        style={{
          top: (hoverRect?.y + 1) * SQUARE_SIZE,
          left: (hoverRect?.x + 1) * SQUARE_SIZE,
          // opacity: isRectOverEmptyCell(hoverRect, layout) ? 0.2 : 0,
          display: isEditingDisabled || isMouseOverComponent ? "none" : "flex",
        }}
      >
        {tooltipMsg}
      </MessageHoverRect>
      {Children?.map(children, child => (
        <DraggableItem
          onMouseOver={() => setIsMouseOverComponent(true)}
          onMouseOut={() => setIsMouseOverComponent(false)}
          isEditingDisabled={isEditingDisabled}
          key={child?.key}
          style={{
            top: layout?.[child?.key]?.y * SQUARE_SIZE,
            left: layout?.[child?.key]?.x * SQUARE_SIZE,
            width: layout?.[child?.key]?.w * SQUARE_SIZE,
            height: layout?.[child?.key]?.h * SQUARE_SIZE,
          }}
        >
          <MoveHandle
            draggable
            onDragStart={() => setKeyBeingMoved(child?.key)}
            isEditingDisabled={isEditingDisabled}
          >
            <OpenWith
              style={{ height: `${SQUARE_SIZE}px`, width: `${SQUARE_SIZE}px` }}
            />
          </MoveHandle>
          <ResizeHandle
            draggable
            onDragStart={() => setKeyBeingResized(child?.key)}
            isEditingDisabled={isEditingDisabled}
          />
          <ItemContent
            isEditingDisabled={isEditingDisabled}
            isDragging={isDragging}
            // style={{
            //   border: isDragging
            //     ? "1px dashed #b8b8b8"
            //     : "1px dashed transparent",
            // }}
          >
            {child}
          </ItemContent>
          {areItemsRemovable && (
            <CrossHandle
              isEditingDisabled={isEditingDisabled}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onDeleteKey(child?.key);
                setIsMouseOverComponent(false);
              }}
            >
              <CrossIcon height="12px" />
            </CrossHandle>
          )}
        </DraggableItem>
      ))}
    </Container>
  );
};

export default GridDraggable;
