import { isEmpty, isNil, set } from "lodash";
import styled from "styled-components";

import { PencilIcon, WarningIcon } from "components/ui/Icons";
import TooltipNew from "components/ui/TooltipNew";
import { useRef } from "react";
import { useEffect } from "react";
import { jsonParse, parseJson, safeFormat } from "utils/common";
import RecordsTagsInput from "components/RecordTagsInput";
import { useParams } from "react-router-dom";
import { EditAggComponentModal, reshapeRecords } from "pages/apps/dashboard";
import RecordsPlotSql from "components/RecordsPlotSql";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getPlotComponent } from "components/plots";
import LongTextEditorModal from "components/LongTextEditorModal";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0px;
  align-items: center;
  padding-right: 0px;
  min-width: 20px;
  width: 100%;

  svg {
    /* height: 12px; */
    fill: #8d8d8d;
    cursor: pointer;
    :hover {
      fill: ${props => props.theme.color.primary};
    }
  }
`;

const FieldInput = styled.input`
  background-color: transparent;
  color: black;
  padding: 0px 2px;
  font-size: 14px;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const FieldArea = styled.textarea`
  background-color: transparent;
  color: black;
  padding: 0px 2px;
  font-size: 14px;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;

  width: 100%;
  height: 100px;
  resize: vertical;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const FieldSelect = styled.select`
  background-color: transparent;
  color: black;
  padding: 0px 2px;
  font-size: 14px;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const TipContainer = styled.div`
  background-color: black;
  color: white;
  padding: 4px;
`;

const DisplayValue = styled.div`
  display: flex;
  ${props => props.isNumber && "text-align: right;"}

  background-color: transparent;
  color: black;
  padding: 2px 0;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  font-family: "Montserrat", sans-serif;
  border: 1px solid transparent;
  font-weight: 500;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const QuillContainer = styled.div`
  border: 1px solid #eaeaea;
  .ql-editor {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    ${props => props.longTextMinHeightActive && `height: 165px;`}
  }
  .ql-toolbar {
    padding: 0;
  }
`;

const DisplayValueLongText = styled.div`
  background-color: transparent;
  color: black;
  padding: 2px 0;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  font-family: "Montserrat", sans-serif;
  border: 1px solid #eaeaea;
  font-weight: 500;

  white-space: pre-wrap;
  height: 70px;
  line-height: 1.2;
  overflow: auto;
  text-overflow: ellipsis;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #a8a8a8;
  }

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const renderLinks = text => {
  try {
    return text?.replace(
      /(https?:\/\/[^\s<]+)/g,
      (match, url, offset, fullText) => {
        // Check if this URL is inside an existing <a> tag
        const beforeText = fullText.slice(0, offset);
        const isInsideAnchorTag =
          beforeText.lastIndexOf("<a ") > beforeText.lastIndexOf("</a>");

        return isInsideAnchorTag ? match : `<a href="${url}">${url}</a>`;
      }
    );
  } catch {
    return text;
  }
};

const DATA_TYPE_TO_INPUT_TYPE = {
  TEXT: "text",
  NUMBER: "number",
  DATETIME: "date",
  TABLE: "table",
};

const onResizeColumn = pipelineConfigId => {
  if (!pipelineConfigId) {
    return;
  }

  const localConfigStr = localStorage.getItem(pipelineConfigId);
  const localConfig = parseJson(localConfigStr);
  const newConfig = { ...localConfig };

  document
    .querySelectorAll("textarea[data-column-db-name]")
    .forEach(textArea => {
      const columnDbName = textArea.getAttribute("data-column-db-name");
      const height = textArea.style.height;
      if (height) {
        set(newConfig, `nameToHeight.${columnDbName}`, height);
      }
    });

  const newConfigStr = JSON.stringify(newConfig);
  localStorage.setItem(pipelineConfigId, newConfigStr);
};

const getHeightStyleFromLocalStorage = (pipelineConfigId, column) => {
  if (!pipelineConfigId || !column?.dbName) {
    return {};
  }

  const localConfigStr = localStorage.getItem(pipelineConfigId);
  const localConfig = parseJson(localConfigStr);
  const nameToHeight = localConfig?.nameToHeight || {};

  let heightStyle = {};
  if (nameToHeight?.[column?.dbName]) {
    heightStyle = { height: nameToHeight?.[column?.dbName] };
  }

  return heightStyle;
};

function htmlToPlainTextWithLinks(html) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  // Recursive function to process nodes
  function processNode(node) {
    let result = "";
    if (node.nodeType === Node.TEXT_NODE) {
      result += node.textContent.trim();
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      if (node.nodeName === "A") {
        const href = node.getAttribute("href") || "";
        result += `${node.textContent.trim()} ${href}`;
      } else {
        node.childNodes.forEach(child => {
          result += processNode(child);
        });
      }
    }

    return result + " ";
  }

  // Start processing from the body element
  return processNode(doc.body).trim();
}

const getNewTextValue = (textElement, text) => {
  if (!textElement || !text) {
    return "";
  }

  const beforeCaret = textElement.value.substring(
    0,
    textElement.selectionStart
  );
  const afterCaret = textElement.value.substring(
    textElement.selectionEnd,
    textElement.value.length
  );

  const newValue = beforeCaret + text + afterCaret;

  // Position the caret after the inserted text
  const position = beforeCaret.length + text.length;
  textElement.selectionStart = position;
  textElement.selectionEnd = position;

  return newValue;
};

const CURRENCY_FORMATTER = new Intl.NumberFormat("en-US");

const RecordFieldInput = ({
  value = "",
  onChange = e => {},
  onBlur = e => {},
  column = {},
  style = {},
  rawValue = "",
  displayValue = "",
  isEditMode = false,
  isInline = false,
  // TODO: temp analytics
  pipelineConfig = {},
  onSaveComponent = () => {},
  record = {},
  recordNameColumn = {},
  longTextMinHeightActive = false,
}) => {
  const { pipelineConfigId, selectedRecordId } = useParams();
  const inputRef = useRef(null);
  let type = DATA_TYPE_TO_INPUT_TYPE?.[column?.type] || "text";
  // temp
  const [execResult, setExecResult] = useState({});

  let heightStyle = getHeightStyleFromLocalStorage(pipelineConfigId, column);
  if (isEmpty(heightStyle)) {
    heightStyle = style;
  }

  useEffect(() => {
    if (!inputRef?.current) {
      return;
    }

    const observer = new ResizeObserver(() => onResizeColumn(pipelineConfigId));
    observer.observe(inputRef.current);

    return () => observer.disconnect();
  }, [inputRef?.current]);

  useEffect(() => {
    // if categorical value if broken, auto set it
    if (isEditMode && column?.formatType === "Categorical") {
      const allowedValues =
        column?.categories?.map(catStr => catStr?.split(",")?.[0]) || [];
      if (allowedValues?.includes(value)) {
        return;
      }

      // assign default if not allowed
      const defaultValue =
        column?.categories
          ?.find(catStr => catStr?.split(",")?.[2] === "true")
          ?.split(",")?.[0] || allowedValues?.[0];
      onChange({ target: { value: defaultValue } });
    }
  }, [isEditMode, inputRef?.current, value, JSON.stringify(column)]);

  const onPaste = e => {
    // e.preventDefault();
    // const cData = e.clipboardData || window.clipboardData;
    // const htmlText =
    //   cData?.getData("text/html") || cData?.getData("text/plain");
    // const plainText = htmlToPlainTextWithLinks(htmlText);
    // const newValue = getNewTextValue(e.target, plainText);
    // onChange({ target: { value: newValue } });
  };

  let bgColor = "transparent";
  if (column?.formatType === "Categorical") {
    const categoryStr = column?.categories?.find(
      c => c?.split(",")?.[0] === value
    );
    const categoryColor = categoryStr?.split(",")?.[1];
    bgColor = `${categoryColor}44`;
  }
  if (column?.formatType === "Rank") {
    const categoryStr = column?.categories?.[value];
    const categoryColor = categoryStr?.split(",")?.[1];
    bgColor = `${categoryColor}44`;
  }
  let bgStyle = { backgroundColor: bgColor };

  if (column?.actionType === "analytics") {
    let aggComponent = jsonParse(value);
    const recordName = record?.fields?.[recordNameColumn?.dbName]?.value;
    const prefixedNlc = `This investor is called ${recordName}. ${column?.description} (source1)`;

    if (isEmpty(aggComponent)) {
      aggComponent = {
        id: column.dbName,
        label: column.displayName,
        nlc: prefixedNlc,
      };
    }
    aggComponent.nlc = prefixedNlc;
    const execResult = aggComponent?.data?.execResult || {};
    const plot = aggComponent?.data?.plots?.[0] || execResult?.displayConfig;

    const tableColumns = Object.entries(execResult?.columnTypes || {}).map(
      ([key, value]) => ({
        name: key,
      })
    );

    let plotContent = (
      <RecordsPlotSql
        type={plot?.type}
        records={execResult?.records}
        columnTypes={execResult?.columnTypes}
        isLoading={!execResult?.records}
      />
    );
    const plots = aggComponent?.data?.plots || [execResult?.displayConfig];

    plotContent = (
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          overflow: "auto",
        }}
      >
        {plots?.map((plot, index) => {
          const plotType = plot?.type;
          let plotProps = {
            params: plot || {},
            isEditing: false,
            onChangeParams: () => {},
            tableColumns,
            records: reshapeRecords(execResult?.records),
            // height: 180,
          };

          let plotContent = (
            <RecordsPlotSql
              type={plot?.type}
              records={execResult?.records}
              columnTypes={execResult?.columnTypes}
              isLoading={!execResult?.records}
              yColumnNames={plot?.yColumnNames}
            />
          );
          if (plotType !== "Table") {
            plotContent = getPlotComponent(plotType, plotProps);
          }

          return (
            <div key={index} style={{ marginBottom: 10 }}>
              {plotContent}
            </div>
          );
        })}
      </div>
    );
    if (execResult?.questionAnswer) {
      plotContent = (
        <DisplayValueLongText style={{ padding: 4, width: "100%" }}>
          {execResult?.questionAnswer}
        </DisplayValueLongText>
      );
    }

    return (
      <Container
        style={{
          // justifyItems: "start",
          alignItems: "start",
          gridTemplateColumns: "1fr auto",
          gap: 4,
          padding: 4,
          borderRadius: 4,
        }}
      >
        {plotContent}
        <EditAggComponentModal
          aggComponent={aggComponent}
          pipelineConfig={pipelineConfig}
          onPressSave={onSaveComponent}
          // temp
          recordId={selectedRecordId}
        />
      </Container>
    );
  }

  if (isEditMode) {
    if (column?.formatType === "Categorical" || column?.formatType === "Rank") {
      let categories = column?.categories || [];
      return (
        <Container style={style}>
          <FieldSelect
            ref={inputRef}
            style={bgStyle}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          >
            {categories?.map((option, i) => {
              const categoryName = option?.split(",")?.[0];
              let categoryValue = categoryName;
              if (column?.formatType === "Rank") {
                categoryValue = i;
              }

              return (
                <option key={option} value={categoryValue}>
                  {categoryName}
                </option>
              );
            })}
          </FieldSelect>
        </Container>
      );
    }

    if (column?.formatType === "Tags") {
      return (
        <Container style={style}>
          <RecordsTagsInput
            isEditMode
            column={column}
            value={value}
            onChange={onChange}
            isInline={isInline}
          />
        </Container>
      );
    }

    if (column?.formatType === "Long Text" && !isInline) {
      return (
        <Container style={style}>
          <QuillContainer longTextMinHeightActive={longTextMinHeightActive}>
            <ReactQuill
              theme="snow"
              defaultValue={value}
              onChange={newValue => {
                onChange({ target: { value: newValue } });
              }}
            />
          </QuillContainer>
          {/* <FieldArea
            data-column-db-name={column?.dbName}
            ref={inputRef}
            style={{ ...style, ...heightStyle }}
            type={type}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onPaste={onPaste}
          /> */}
        </Container>
      );
    }

    let inputValue = value;
    if (
      column?.type === "DATETIME" &&
      typeof value === "string" &&
      value?.length > 10
    ) {
      inputValue = safeFormat(value, "yyyy-MM-dd");
    }

    return (
      <Container style={style}>
        <FieldInput
          autoFocus={column?.type === "DATETIME"}
          ref={inputRef}
          style={style}
          type={type}
          value={inputValue || ""}
          onChange={onChange}
          onBlur={onBlur}
          onPaste={onPaste}
        />
      </Container>
    );
  }

  if (column?.formatType === "Rank") {
    displayValue = column?.categories?.[value]?.split(",")?.[0];
  }
  if (column?.type === "DATETIME") {
    displayValue = safeFormat(value, "dd MMM yyyy");
    if (!value) {
      displayValue = "";
    }
  }

  let valueToShow = renderLinks(displayValue || value);

  if (rawValue && (isNil(value) || value === "")) {
    return (
      <Container style={style}>
        <TooltipNew
          tipContent={
            <TipContainer>
              <div>Could not parse:</div>
              <br />
              <div>"{rawValue}"</div>
              <br />
              <div>Please fill in manually</div>
            </TipContainer>
          }
          style={{
            overflow: "hidden",
            display: "grid",
            gridTemplateColumns: "1fr auto",
            gap: 5,
            alignItems: "center",
          }}
        >
          <DisplayValue isNumber={column?.type === "NUMBER"}>
            {rawValue}
          </DisplayValue>
          <WarningIcon style={{ fill: "#d4a600", cursor: "default" }} />
        </TooltipNew>
      </Container>
    );
  }

  if (column?.formatType === "Long Text" && !isInline) {
    return (
      <Container style={style}>
        <DisplayValueLongText
          style={heightStyle}
          dangerouslySetInnerHTML={{
            __html: valueToShow,
          }}
        />
      </Container>
    );
  }

  if (column?.formatType === "Long Text" && isInline) {
    return <Container style={style}>-</Container>;
  }

  if (column?.formatType === "Tags") {
    return (
      <Container style={style}>
        <RecordsTagsInput column={column} value={value} isInline={isInline} />
      </Container>
    );
  }

  if (column?.type === "NUMBER") {
    valueToShow = CURRENCY_FORMATTER.format(value);
  }

  return (
    <Container style={style}>
      <DisplayValue
        isNumber={column?.type === "NUMBER"}
        style={bgStyle}
        dangerouslySetInnerHTML={{
          __html: valueToShow,
        }}
      />
    </Container>
  );
};

export default RecordFieldInput;
