import { Gap } from "components/Layout";
import ButtonWord from "components/ui/ButtonWord";
import { PlusIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import { useState } from "react";
import styled from "styled-components";

const ModalContent = styled.div`
  position: relative;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  width: 600px;
  height: 400px;
  overflow: auto;
`;

const Title = styled.div`
  font-weight: 500;
`;

const BottomContainer = styled.div`
  position: absolute;
  bottom: 20px;
  padding-top: 10px;
  width: 560px;
  background-color: white;
  border-top: 1px solid #d8d8d8;
  display: grid;
  justify-content: end;
`;

const Trigger = styled.div``;

const StyledInput = styled.input`
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px 8px;
  font-weight: 500;
`;

const INITIAL_TOKEN_BODY = {
  name: "",
  expiredIn: "3 Months",
  scopeIds: [],
};

const AddApiKeyModal = ({ onClickApply = tokenBody => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tokenBody, setTokenBody] = useState(INITIAL_TOKEN_BODY);

  const onClickApplyBtn = () => {
    onClickApply(tokenBody);
    setIsOpen(false);
    setTokenBody(INITIAL_TOKEN_BODY);
  };

  return (
    <>
      <Trigger onClick={() => setIsOpen(true)}>
        <PlusIcon />
      </Trigger>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Title>Set up token</Title>
          <Gap />
          <StyledInput
            placeholder="Name"
            value={tokenBody.name}
            onChange={e => setTokenBody({ ...tokenBody, name: e.target.value })}
          />
          <BottomContainer>
            <ButtonWord isPrimary onClick={onClickApplyBtn}>
              Create
            </ButtonWord>
          </BottomContainer>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddApiKeyModal;
