import CustomSelect from "components/CustomSelect";
import styled from "styled-components";
import { getColorFromString } from "utils/common";

const StyledInput = styled.input`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 8px 14px;
  font-weight: 500;
  :disabled {
    opacity: 0.5;
  }
`;

const StyledSelect = styled.select`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 8px 14px;
  font-weight: 500;
  :disabled {
    opacity: 0.5;
  }
`;

const StyledCustomSelect = styled(CustomSelect)`
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 8px 14px;
  font-weight: 500;
  :disabled {
    opacity: 0.5;
  }
`;

const ColumnFieldInput = ({
  style = {},
  value = "",
  onChange = e => {},
  columnType = "",
  options = [],
  onFocus = () => {},
  onBlur = () => {},
}) => {
  if (columnType === "select") {
    const backgroundColor = `${getColorFromString(`${value || "Text"}`)}55`;

    // Uncomment this to use old, default html select
    return (
      <StyledSelect
        style={{ ...style, backgroundColor }}
        value={value}
        onChange={onChange}
      >
        {options.map(option => (
          <option key={option?.value} value={option?.value}>
            {option?.label}
          </option>
        ))}
      </StyledSelect>
    );

    // Uncomment this to use custom select
    // return (
    //   <StyledCustomSelect
    //     style={{ ...style, backgroundColor }}
    //     value={value}
    //     onChange={onChange}
    //     options={options}
    //   />
    // );
  }

  return (
    <StyledInput
      onFocus={onFocus}
      onBlur={onBlur}
      style={style}
      value={value}
      onChange={onChange}
    />
  );
};

export default ColumnFieldInput;
