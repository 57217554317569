import HighchartsReact from "highcharts-react-official";
// import Highcharts from "highcharts";
import Highcharts from "highcharts/highstock";
import { cloneDeep } from "lodash";
import styled from "styled-components";
import { getColorFromString } from "utils/common";
import { useState } from "react";
import { useEffect } from "react";

const WrapContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 4px 0;
  max-height: 150px;
  overflow: auto;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
`;

const StyledSelect = styled.select`
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px;
  font-weight: 500;
  /* width: 200px; */
`;

const Name = styled.div`
  white-space: nowrap;
  font-weight: 500;
`;

const NameAndInput = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  margin-bottom: 8px;
`;

export const ANIMATION_MS = 1800;

export const ParamsEditor = ({
  params,
  onChangeParams,
  tableColumns,
  isEditing,
}) => {
  const { xColumnName, yColumnNames } = params;

  if (isEditing) {
    return (
      <div>
        <NameAndInput>
          <Name>X axis:</Name>
          <StyledSelect
            value={xColumnName}
            onChange={e => {
              onChangeParams({
                ...(params || {}),
                xColumnName: e.target.value,
              });
            }}
          >
            <option>-- Choose X Axis --</option>
            {tableColumns.map((column, index) => (
              <option key={index} value={column?.name}>
                {column?.name}
              </option>
            ))}
          </StyledSelect>
        </NameAndInput>
        <WrapContainer>
          {tableColumns
            ?.filter(col => col?.name !== xColumnName)
            ?.map(column => {
              const checked = yColumnNames?.includes(column?.name) || false;

              const onChange = e => {
                const newParams = cloneDeep(params || {});
                let newYColumnNames = [];

                if (e.target.checked) {
                  newYColumnNames = [
                    ...(newParams.yColumnNames || []),
                    column?.name,
                  ];
                }
                if (!e.target.checked) {
                  newYColumnNames = newParams.yColumnNames?.filter(
                    col => col !== column?.name
                  );
                }

                // throw away column names from previous plots
                newYColumnNames = newYColumnNames?.filter(yColumnName =>
                  tableColumns?.find(col => col?.name === yColumnName)
                );

                newParams.yColumnNames = newYColumnNames;
                onChangeParams(newParams);
              };

              return (
                <Label
                  key={column?.name}
                  style={{
                    backgroundColor: `${getColorFromString(
                      `c${column?.name}`
                    )}55`,
                  }}
                >
                  <input
                    // TODO https://stackoverflow.com/questions/43476729/react-checkbox-does-not-update
                    key={Math.random()}
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                  />
                  <span>{column?.name}</span>
                </Label>
              );
            })}
        </WrapContainer>
      </div>
    );
  }

  return null;
  // return (
  //   <WrapContainer>
  //     {yColumnNames?.map(columnName => {
  //       return (
  //         <Label
  //           key={columnName}
  //           style={{
  //             backgroundColor: `${getColorFromString(`c${columnName}`)}55`,
  //           }}
  //         >
  //           <span>{columnName}</span>
  //         </Label>
  //       );
  //     })}
  //   </WrapContainer>
  // );
};

const OPTIONS = {
  chart: {
    map: {},
  },

  title: {
    text: "Life expectancy by country (2021)",
    align: "left",
  },

  credits: {
    href: "https://data.worldbank.org",
    mapText: " Data source: The World Bank",
  },

  mapNavigation: {
    enabled: true,
    buttonOptions: {
      verticalAlign: "bottom",
    },
  },

  colorAxis: {
    min: 60,
  },

  data: {},

  tooltip: {
    valueDecimals: 1,
    valueSuffix: " years",
  },

  series: [
    {
      name: "Life expectancy",
      joinBy: ["iso-a3", "code"],
      dataLabels: {
        enabled: true,
        format: "{point.value:.0f}",
        filter: {
          operator: ">",
          property: "labelrank",
          value: 250,
        },
        style: {
          fontWeight: "normal",
        },
      },
    },
  ],
};

const Container = styled.div``;

const XXChart = ({
  params = {},
  isEditing = false,
  onChangeParams = newParams => {},
  tableColumns = [],
  records = [],
  height = 220,
}) => {
  const [options, setOptions] = useState(OPTIONS);

  useEffect(() => {
    doFetchMapData();
  }, [JSON.stringify(params), JSON.stringify(records), height]);

  const doFetchMapData = async () => {
    const topology = await fetch(
      "https://code.highcharts.com/mapdata/custom/world.topo.json"
    ).then(response => response.json());

    const newOptions = cloneDeep(options);
    newOptions.chart.map = topology;
    setOptions(newOptions);
  };

  return (
    <Container>
      <ParamsEditor
        params={params}
        onChangeParams={onChangeParams}
        tableColumns={tableColumns}
        isEditing={isEditing}
      />
      {/* chart here */}
      <HighchartsReact
        // constructorType={"stockChart"}
        highcharts={Highcharts}
        options={options}
      />
    </Container>
  );
};

export default XXChart;
