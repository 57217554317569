import { CircularProgress, Table } from "@material-ui/core";
import { Filter } from "@material-ui/icons";
import EditSigmaRecordFieldModal from "components/EditSigmaRecordFieldModal";
import {
  BinIcon,
  DocSearchIcon,
  FunnelIcon,
  TableGridIcon,
} from "components/IconsNew";
import InlineFieldInputCell from "components/InlineFieldInput";
import InlineFieldInput from "components/InlineFieldInput";
import SortAndFilterConfigModal from "components/SortAndFilterConfigModal";
import ButtonWord from "components/ui/ButtonWord";
import { FilesIcon, GearIcon } from "components/ui/Icons";
import Tooltip from "components/ui/Tooltip";
import TooltipNew from "components/ui/TooltipNew";
import { set } from "lodash";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { parseJson } from "utils/common";

const TableContainer = styled.div`
  overflow: auto;

  table {
    white-space: nowrap;
    margin: 0;
    border: none;
    border-collapse: separate;
    width: max-content;
  }
  table thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
  }

  table tbody th {
    position: relative;
  }
  table thead th:first-child {
    width: 1%;
    position: sticky;
    left: 0;
    z-index: 2;
    border-right: 1px solid ${props => props.theme.color.closer1};
  }
  table tbody th:first-child {
    border-right: 1px solid ${props => props.theme.color.closer1};
  }
  table tbody th {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
  }
`;

const StyledBinIcon = styled(BinIcon)``;

const CellActions = styled.div`
  opacity: 0;
  background-color: white;

  position: absolute;
  top: 8px;
  right: 2px;

  display: flex;
  gap: 0px;
  align-items: center;

  svg {
    padding: 2px;
    height: 18px !important;
    width: 18px !important;
    border-radius: 2px;

    :hover {
      background-color: #cecece;
    }
  }
`;

const StyledEditSigmaRecordFieldModal = styled(EditSigmaRecordFieldModal)`
  transition: opacity 0.2s;
`;

const Td = styled.td`
  background-color: white;
  position: relative;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  font-weight: 500;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
  text-overflow: ellipsis;
  overflow: hidden;
  svg {
    fill: black;
    height: 14px;
  }

  :hover {
    background-color: #f3f5f7;
    ${CellActions} {
      opacity: 1;
    }
  }
`;

const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;

  text-overflow: ellipsis;
  overflow: auto;

  font-weight: 600;
  z-index: 1;
  resize: horizontal;

  ${props => props.isResizing && "border-right: 1px solid #00000022;"}

  ::-webkit-resizer {
    background-color: transparent;
  }
`;

const Tr = styled.tr`
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const NoRecordsDiv = styled.div`
  height: 100%;
  border: 1px solid ${props => props.theme.color.closer1};
  border-left: none;
  border-right: none;
  display: flex;
  text-align: center;
  align-items: center;
  color: ${props => props.theme.color.closer1};
  justify-content: center;
  font-weight: 600;
  position: sticky;
  left: 0;
  gap: 20px;
  svg {
    color: ${props => props.theme.color.primary}55;
  }
`;

const MaxWidthDiv = styled.div`
  white-space: nowrap;
  max-width: 200px;
`;

const EllpsisDiv = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconAndText = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 0px;
  align-items: center;
  min-width: 100px;
`;

const HasMoreBtn = styled(ButtonWord)`
  margin-top: 20px;
  margin-bottom: 20px;
  width: max-content;
  position: sticky;
  left: 50%;
  transform: translateX(-50%);
`;

const TextAndIconHover = styled.div`
  padding: 8px;
  display: grid;
  grid-template-columns: auto auto;
  gap: 4px;
  svg {
    cursor: pointer;
    opacity: 0;
  }

  :hover {
    svg {
      opacity: 1;
    }
  }
`;

const DISABLED_STYLE = {
  pointerEvents: "none",
  opacity: 0.5,
};

const PipelineRecordsTable = ({
  readOnly = false,
  columns = [],
  pipelineConfig = {},
  sigmaRecords = [],
  initialRecordIds = [],
  onClickLoadMore = () => {},
  onRecordPatchComplete = (recordId, fields) => {},
  onClickDeleteRecord = recordId => {},
  isShowMoreHidden = false,
  isLoading = false,
  tableId = "",
  isLoadingNewPage = false,
  sigmasRes = {},
  sortAndFilterConfig = {},
  onChangeConfig = newConfig => {},
  nameToWidth = {},
  componentId = "",
  onResizeColumn = () => {},
}) => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    if (!ref?.current || !pipelineConfig?.id) {
      return;
    }

    const observer = new ResizeObserver((entries, observer) => {
      if (!ref?.current) {
        return;
      }
      onResizeColumn();
    });
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [ref?.current, pipelineConfig?.id, componentId]);

  if (isLoading) {
    return (
      <TableContainer>
        <NoRecordsDiv>
          Loading... <CircularProgress thickness={10} size={18} />
        </NoRecordsDiv>
      </TableContainer>
    );
  }

  if (!sigmaRecords?.length) {
    return (
      <TableContainer>
        <NoRecordsDiv>No records</NoRecordsDiv>
      </TableContainer>
    );
  }

  const sortedColumns = columns
    ?.filter(column => !column?.name?.includes("_url_"))
    ?.sort((a, b) => {
      // if id column, show first
      if (a?.displayGroup === "") {
        return -1;
      }
      return 1;
    });

  return (
    <TableContainer style={isLoadingNewPage ? DISABLED_STYLE : {}}>
      <table>
        <thead>
          <tr ref={ref}>
            {sortedColumns?.map((column, i) => {
              const columnName = column?.displayName;
              let style = {};
              if (nameToWidth?.[column?.dbName]) {
                style = { width: nameToWidth?.[column?.dbName] };
              }
              return (
                <Th
                  style={style}
                  data-column-db-name={column?.dbName}
                  data-component-id={componentId}
                  key={`${tableId}-${column?.dbName}`}
                  onMouseDown={() => setIsResizing(true)}
                  onMouseUp={() => setIsResizing(false)}
                  isResizing={isResizing}
                >
                  <TextAndIconHover>
                    {columnName}
                    <SortAndFilterConfigModal
                      trigger={<GearIcon />}
                      pipelineConfigId={pipelineConfig?.id}
                      columns={[column]}
                      sortAndFilterConfig={sortAndFilterConfig}
                      onChangeConfig={onChangeConfig}
                    />
                  </TextAndIconHover>
                </Th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {sigmaRecords.map(record => (
            <Tr key={`record-${record?.id}`}>
              {sortedColumns?.map((column, i) => {
                let recordBaseUrl = "/apps";
                if (window?.location?.pathname?.includes("/apps-new/")) {
                  recordBaseUrl = "/apps-new";
                }
                let recordUrl = `${recordBaseUrl}/${pipelineConfig?.id}/records/${record?.id}?tableId=${tableId}&count=${sigmasRes?.count}`;

                const onClick = () => navigate(recordUrl);

                const { value, rawValue } =
                  record?.fields?.[column?.dbName] || {};
                let cellValue = value ?? rawValue;
                if (!!cellValue && typeof cellValue === "object") {
                  cellValue = JSON.stringify(cellValue);
                }

                if (i === 0) {
                  let highlightStyle = {};
                  // if (!initialRecordIds?.includes(record?.id)) {
                  //   highlightStyle = { borderLeft: `4px solid #0191ff` };
                  // }

                  return (
                    <InlineFieldInputCell
                      readOnly={readOnly}
                      key={`${column?.dbName}-${record?.id}`}
                      style={{ maxWidth: 400, ...highlightStyle }}
                      record={record}
                      column={column}
                      onRecordPatchComplete={onRecordPatchComplete}
                      isHeader
                      additionalActions={[
                        <StyledBinIcon
                          onClick={e => {
                            e.stopPropagation();
                            onClickDeleteRecord(record?.id);
                          }}
                        />,
                      ]}
                      leadIcon={
                        <Link to={recordUrl}>
                          <DocSearchIcon />
                        </Link>
                      }
                    />
                  );
                }

                if (column?.type === "TABLE") {
                  return (
                    <Td key={column?.name} onClick={onClick}>
                      <IconAndText style={{ color: "#7e7e7e" }}>
                        <TableGridIcon style={{ fill: "#fd9138" }} />
                        Go to table
                      </IconAndText>
                    </Td>
                  );
                }

                let urlColumn = `${column?.name}_url_`;
                if (record?.fields?.[urlColumn]?.value) {
                  const url = record?.fields?.[urlColumn]?.value;
                  return (
                    <Td key={column?.name}>
                      <a href={url} target="_blank" rel="noreferrer">
                        {cellValue}
                      </a>
                    </Td>
                  );
                }

                return (
                  <InlineFieldInputCell
                    readOnly={readOnly}
                    key={`${column?.dbName}-${record?.id}`}
                    record={record}
                    column={column}
                    onRecordPatchComplete={onRecordPatchComplete}
                  />
                );
              })}
            </Tr>
          ))}
        </tbody>
      </table>
      <HasMoreBtn
        style={{
          visibility:
            isShowMoreHidden && !isLoadingNewPage ? "hidden" : "visible",
        }}
        disabled={isLoadingNewPage}
        isPrimary
        onClick={onClickLoadMore}
      >
        Load more
      </HasMoreBtn>
    </TableContainer>
  );
};

export default PipelineRecordsTable;
