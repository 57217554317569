import {
  zapiDelete,
  zapiGet,
  zapiPatch,
  zapiPost,
  zapiPostFile,
} from "api/zapi";
import {
  apiGet,
  apiGetRawResponse,
  apiPatch,
  apiPost,
} from "api/api-http-methods";

export const getDealNames = async params => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/cc/deal-names`,
    params
  );
  return { data, error };
};

export const getFiles = async params => {
  const { data, error } = await zapiGet(`/solutions/ocr/api/v1/files`, params);
  return { data, error };
};

export const getFile = async (fileId, params = {}) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/files/${fileId}`,
    params
  );
  return { data, error };
};

export const postFile = async file => {
  const { data, error } = await zapiPostFile(
    `/solutions/ocr/api/v1/files`,
    null,
    file
  );
  return { data, error };
};

export const postBulkUpload = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/files/bulk-upload`,
    params,
    body
  );
  return { data, error };
};

export const patchFileById = async (fileId, fileBody) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/api/v1/files/${fileId}`,
    null,
    fileBody
  );
  return { data, error };
};

export const importFileWithUrl = async url => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/files/link`,
    { url },
    null
  );
  return { data, error };
};

export const importFileWithUrlsAndRandomPage = async urls => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/files/links`,
    { randomPage: true },
    { urls }
  );
  return { data, error };
};

export const deleteAllTrainingFiles = async () => {
  const { data, error } = await zapiDelete(
    "/solutions/ocr/api/v1/training-files"
  );
  return { data, error };
};

export const postContriubutor = async (fileId, queryParams) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/files/${fileId}/contributors`,
    queryParams,
    null
  );
  return { data, error };
};

export const deleteContributor = async (fileId, contributorUserName) => {
  const { data, error } = await zapiDelete(
    `/solutions/ocr/api/v1/files/${fileId}/contributors/${contributorUserName}`
  );
  return { data, error };
};

export const getFileById = async fileId => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/files/${fileId}`
  );
  return { data, error };
};

export const deleteFileById = async fileId => {
  const { data, error } = await zapiDelete(
    `/solutions/ocr/api/v1/files/${fileId}`
  );
  return { data, error };
};

export const getFilePageResults = async (fileId, pageNumber) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/files/${fileId}/page-results/${pageNumber}`
  );
  return { data, error };
};

export const getExcelFilePageResults = async fileId => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/files/${fileId}/page-results/0`,
    { format: "excel" }
  );
  return { data, error };
};

export const getPreDefinedLabelSets = async () => {
  const { data, error } = await zapiGet("/solutions/ocr/api/v1/label-sets");
  return { data: data, error };
};

export const updatePreDefinedLabelSet = async userLabelSet => {
  const { data, error } = await zapiPatch(
    "/solutions/ocr/api/v1/label-sets",
    null,
    userLabelSet
  );
  return { data, error };
};

export const addPreDefinedLabels = async userLabelSet => {
  const { data, error } = await zapiPost(
    "/solutions/ocr/api/v1/label-sets",
    null,
    userLabelSet
  );
  return { data, error };
};

export const shareLabelset = async (id, user) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/label-sets/${id}`,
    { collaborator: user }
  );
  return { data, error };
};

export const getActivatedLabelSetId = async () => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/api/v1/active-label-set"
  );
  return { data, error };
};

export const setAcitvatedLabelSetId = async labelSetId => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/api/v1/active-label-set/${labelSetId}`
  );
  return { data, error };
};

export const getAnnotationsByFileAndPage = async (fileId, pageNumber) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/files/${fileId}/pages/${pageNumber}/annotations`
  );
  return { data, error };
};

export const getAnnotations = async (fileId, pageNumber, outputId, params) => {
  const { data, error } = await apiGet(
    `/bz-api/v1/files/${fileId}/pages/${pageNumber}/annotations/${outputId}`,
    params
  );
  return { data, error };
};

export const patchAnnotations = async (fileId, pageNumber, outputId, body) => {
  const { data, error } = await apiPatch(
    `/bz-api/v1/files/${fileId}/pages/${pageNumber}/annotations/${outputId}`,
    {},
    body
  );
  return { data, error };
};

export const saveAnnotationsByFileAndPage = async (
  fileId,
  pageNumber,
  body
) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/api/v1/files/${fileId}/pages/${pageNumber}/annotations`,
    null,
    body
  );
  return { data, error };
};

export const searchWithKeyWords = async keyword => {
  const { data, error } = await zapiGet("/solutions/ocr/api/v1/tables", {
    keyword,
  });
  return { data, error };
};

export const triggerDownloadOfAnnotationData = async () => {
  const res = await apiGetRawResponse(
    `/solutions/ocr/api/v1/files/annotations`
  );
  const blob = await res.blob();
  const objectUrl = window.URL.createObjectURL(blob);

  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  anchor.href = objectUrl;
  anchor.download = "annotations.json";
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);
};

export const triggerDownloadOfFile = async (fileId, params = {}) => {
  const res = await apiGetRawResponse(
    `/solutions/ocr/api/v1/files/${fileId}/content`,
    params
  );
  const blob = await res.blob();
  const objectUrl = window.URL.createObjectURL(blob);

  const contentDispositionHeader = res?.headers.get("Content-Disposition");
  const fileName = contentDispositionHeader?.split("filename=")?.[1];

  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  anchor.href = objectUrl;
  anchor.download = fileName || "file.pdf";
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);
};

export const postFilesRerunForFileId = async fileId => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/files/rerun/${fileId}`
  );
  return { data, error };
};

export const postFilesRerunForFileIdForPage = async (fileId, pageNumber) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/files/rerun/${fileId}/page/${pageNumber}`
  );
  return { data, error };
};

export const getCommentsByFileAndPage = async (fileId, pageNumber) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/files/${fileId}/pages/${pageNumber}/comments`
  );
  return { data, error };
};

export const postCommentsForFileAndPage = async (fileId, pageNumber, body) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/files/${fileId}/pages/${pageNumber}/comments`,
    null,
    body
  );
  return { data, error };
};

export const postIngestPublicContent = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/files/ingest-public-content`,
    params,
    body
  );
  return { data, error };
};

export const postUploadForDatabase = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    `/bz-api/v1/files/upload-for-database`,
    params,
    body
  );
  return { data, error };
};

export const postAudioFiles = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/audio-files`,
    params,
    body
  );
  return { data, error };
};

export const getOneFile = async (params = {}) => {
  const { data, error } = await apiGet(
    `/solutions/ocr/file-service/api/v1/file`,
    params
  );
  return { data, error };
};

export const postIngestWebpage = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    `/bz-api/v1/files/ingest-webpage`,
    params,
    body
  );
  return { data, error };
};

export const getFilesBz = async (params = {}) => {
  const { data, error } = await apiGet(`/bz-api/v1/files`, params);
  return { data, error };
};

export const getFileMeta = async (fileId = "", params = {}) => {
  const { data, error } = await apiGet(
    `/bz-api/v1/files/${fileId}/meta`,
    params
  );
  return { data, error };
};

export const patchFileMeta = async (fileId = "", params = {}, body = {}) => {
  const { data, error } = await apiPatch(
    `/bz-api/v1/files/${fileId}/meta`,
    params,
    body
  );
  return { data, error };
};
