import { useState, useRef } from "react";
import styled from "styled-components";

import useClickOutside from "hooks/useClickOutside";

/*
POI colours
colorMap.put("#000000", STHighlightColor.BLACK);
colorMap.put("#0000ff", STHighlightColor.BLUE);
colorMap.put("#00ffff", STHighlightColor.CYAN);
colorMap.put("#008000", STHighlightColor.GREEN);
colorMap.put("#ff00ff", STHighlightColor.MAGENTA);
colorMap.put("#ff0000", STHighlightColor.RED);
colorMap.put("#ffff00", STHighlightColor.YELLOW);
colorMap.put("#ffffff", STHighlightColor.WHITE);
colorMap.put("#000080", STHighlightColor.DARK_BLUE);
colorMap.put("#008080", STHighlightColor.DARK_CYAN);
colorMap.put("#800080", STHighlightColor.DARK_MAGENTA);
colorMap.put("#800000", STHighlightColor.DARK_RED);
colorMap.put("#808000", STHighlightColor.DARK_YELLOW);
colorMap.put("#808080", STHighlightColor.DARK_GRAY);
colorMap.put("#c0c0c0", STHighlightColor.LIGHT_GRAY);
colorMap.put("none", STHighlightColor.NONE);
*/

const SHADES_OF_GREY = ["#000000", "#808080", "#c0c0c0", "transparent"];
const OTHER_COLORS = [
  "#0000ff",
  "#00ffff",
  "#008000",
  "#ff00ff",
  "#ff0000",
  "#ffff00",
  "#ffffff",
  "#000080",
  "#008080",
  "#800080",
  "#800000",
  "#808000",
  "#808080",
];

const Container = styled.div`
  position: relative;
`;

const Tip = styled.div`
  position: absolute;
  left: 0;
  width: max-content;
  max-height: 300px;
  overflow: auto;

  background-color: ${props => props.theme.color.furthest};
  z-index: 110;
  box-shadow: 0 8px 24px
    ${props =>
      props.theme.name === "light"
        ? "rgba(140, 149, 159, 0.2)"
        : "rgba(0, 0, 0, 0.4)"};
  font-weight: 400;

  display: ${props => (props.isVisible ? "flex" : "none")};
  flex-direction: column;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const Trigger = styled.div`
  svg {
    path:nth-child(${props => props.highlightChild}) {
      fill: ${props => props.selectedColor};
      fill-opacity: 1;
    }
  }
`;

const ColorBoxesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 20px);
  padding: 5px;
  gap: 5px;
  z-index: 2;
`;

const ColorBox = styled.div`
  cursor: pointer;
  :hover {
    border: 2px solid ${props => props.theme.color.primary};
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  }
  border: 1px solid ${props => props.theme.color.closer0};
  height: 20px;

  ${props =>
    props.isSelected &&
    `border: 2px solid ${props.theme.color.primary};
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  `}
`;

const Indicator = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  border: 0.5px solid lightgrey;
`;

const ColorPickerTooltip = ({
  highlightChild = 2,
  selectedColor,
  onNewColor,
  triggerIcon,
}) => {
  const tipRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  useClickOutside(tipRef, () => setIsOpen(false));

  const isSelectedColorOneOfExsiting = [
    ...SHADES_OF_GREY,
    ...OTHER_COLORS,
  ].includes(selectedColor);

  return (
    <Container>
      <Trigger
        highlightChild={highlightChild}
        selectedColor={selectedColor}
        onClick={() => setIsOpen(true)}
      >
        {triggerIcon}
        {/* <Indicator style={{ backgroundColor: selectedColor }} /> */}
      </Trigger>
      {isOpen && (
        <Tip ref={tipRef} isVisible={isOpen}>
          <ColorBoxesContainer>
            {SHADES_OF_GREY?.map(color => (
              <ColorBox
                onClick={() => {
                  setIsOpen(false);
                  onNewColor(color);
                }}
                isSelected={selectedColor === color}
                key={color}
                style={
                  color === "transparent"
                    ? {
                        background:
                          "linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%), linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%)",
                        backgroundSize: "20px 20px",
                        backgroundPosition: "0 0, 10px 10px",
                      }
                    : { backgroundColor: color }
                }
              />
            ))}
            {OTHER_COLORS?.map(color => (
              <ColorBox
                onClick={() => {
                  setIsOpen(false);
                  onNewColor(color);
                }}
                isSelected={selectedColor === color}
                key={color}
                style={{ backgroundColor: color }}
              />
            ))}
            {!isSelectedColorOneOfExsiting && (
              <ColorBox
                isSelected
                key={selectedColor}
                style={{ backgroundColor: selectedColor }}
              />
            )}
          </ColorBoxesContainer>
        </Tip>
      )}
    </Container>
  );
};

export default ColorPickerTooltip;
