import { Editor } from "@monaco-editor/react";
import { postAndStreamResponse } from "api/api-http-methods";
import { Gap } from "components/Layout";
import ButtonWord from "components/ui/ButtonWord";
import { PencilIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import { get } from "lodash";
import { useState } from "react";
import ReactQuill from "react-quill";
import styled from "styled-components";

const STEP_0_INPUT_FIELDS = [
  {
    label: "Sequence trigger",
    type: "select",
    path: "type",
    options: [
      {
        label: "Record created",
        value: "record-created",
      },
      {
        label: "Record updated",
        value: "record-updated",
      },
      {
        label: "Manual trigger",
        value: "manually-triggered",
      },
      {
        label: "Upload document",
        value: "document-uploaded",
      },
    ],
  },
];

const STEP_INPUT_FIELDS = [
  {
    type: "text",
    path: "name",
    label: "Name",
  },
  {
    label: "Action type",
    type: "select",
    path: "type",
    options: [
      {
        label: "Send email",
        value: "email",
      },
      {
        label: "Generate word doc",
        value: "generate_word_doc",
      },
      {
        label: "Web Search",
        value: "internet",
      },
      {
        label: "Code",
        value: "code",
      },
    ],
  },
];

const ACTION_TYPE_TO_INPUT_FIELDS = {
  email: [
    {
      type: "number",
      path: "action.offsetMS",
      label: "Delay (milliseconds)",
    },
    { label: "Recipient", type: "text", path: "function.recipients" },
    { label: "Subject", type: "text", path: "function.subject" },
    {
      label: "Template",
      type: "html-editor-prompted",
      path: "function.template",
    },
  ],
  generate_word_doc: [
    { label: "Doc Prompt", type: "textarea-prompted", path: "function.prompt" },
  ],
  internet: [
    { label: "Search query", type: "textarea", path: "function.template" },
  ],
  code: [
    { label: "Code", type: "code-editor-prompted", path: "function.template" },
  ],
};

const ModalContent = styled.div`
  padding: 20px;
  width: 600px;
  height: 485px;
  overflow: auto;

  display: grid;
`;

const BoldDiv = styled.span`
  font-weight: 600;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  padding: 8px;
`;

const FormFields = styled.div`
  padding: 10px;
`;

const Select = styled.select`
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 14px;
  font-weight: 500;
`;

const TextArea = styled.textarea`
  height: 200px;
  resize: none;
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 14px;
  font-weight: 500;
`;

const StyledInput = styled.input`
  height: 36px;
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 14px;
  font-weight: 500;

  :disabled {
    opacity: 0.6;
  }
`;

const StyledPencilIcon = styled(PencilIcon)`
  height: 14px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const QuillContainer = styled.div`
  border: 1px solid #eaeaea;
  .ql-editor {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    height: 200px;
  }
`;

const StyledEditor = styled(Editor)`
  height: 300px;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const FIELDS = [
  { name: "userName", label: "Username", placeholder: "admin", type: "text" },
  { name: "password", label: "Password", placeholder: "*****", type: "text" },
  { name: "host", label: "Host", placeholder: "35.189.120.201", type: "text" },
  { name: "port", label: "Port", placeholder: "5432", type: "text" },
  { name: "database", label: "Database", placeholder: "mydb", type: "text" },
];

const ConnectDatabaseModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ButtonWord
        style={{ alignSelf: "center" }}
        onClick={() => setIsOpen(true)}
      >
        Connect DB
      </ButtonWord>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Title>Connect database</Title>
          <FormFields>
            {FIELDS?.map(field => (
              <>
                <BoldDiv>{field?.label}</BoldDiv>
                <Gap height="10px" />
                <StyledInput key={field.name} placeholder={field.placeholder} />
                <Gap height="10px" />
              </>
            ))}
          </FormFields>
          <ButtonWord
            style={{ width: 100, height: 36, justifySelf: "end" }}
            isPrimary
          >
            Connect
          </ButtonWord>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConnectDatabaseModal;
