import {
  Routes,
  Route,
  Outlet,
  useHref,
  useLocation,
  useNavigate,
  useMatch,
  useParams,
} from "react-router-dom";

import FilePageNew from "pages/files/:fileId";
import AnnotationsConfigPage from "pages/annotationsConfigPage";
import Search from "pages/search";
import LoginPage from "pages/login";
import OneTimeCodePage from "pages/one-time-code";
import StartSearchPage from "pages/start-search";
import SearchEmailsPage from "pages/search-emails";
import TableConfigsPage from "pages/table-configs";
import CreateTableConfigPage from "pages/table-configs/new";
import TableConfigResultsPage from "pages/table-configs/:tableConfigId/results";
import EditTableConfigPage from "pages/table-configs/:tableConfigId/edit";
import TagsPage from "pages/tags";
import TagPage from "pages/tags/:tagId";

import ExcelViewsPage from "pages/excel-views";
import ExcelViewPage from "pages/excel-views/:excelModelId";
import ExcelWebViewPage from "pages/excel-web-views/:excelModelId";
import ProfilePage from "pages/profile";
import ExcelFileOcrPage from "pages/excel-files/:fileId";
import WordDocPage from "pages/word-docs/:wordDocId";
import WordDocsPage from "pages/word-docs";
import CrawlerPage from "pages/crawler";
import SourceFilesPage from "pages/source-files";

import WorkingFilesPage from "pages/working-files";

import EmailFilePage from "pages/email-files/:emailFilIeId";
import WebPagePage from "pages/web-pages/:webPageId";
import TemplateFilesPage from "pages/template-files";
import TemplateDahsboardPage from "pages/template-dashboards/:templateFileId";
import HomePage from "pages";
import ProfileSourcePage from "pages/profile/source";
import ProfileSpacePage from "pages/profile/space";
import ProfilePipelinesPage from "pages/profile/pipelines";
import NewTemplateFilePage from "pages/template-files/new";
import TemplateFileEditPage from "pages/template-files/:wordDocId";
import AuditPage from "pages/audit";
import ChatWordDocPage from "pages/word-docs/:wordDocId/chat";
import ChatWordDocPublishedPage from "pages/word-docs/:wordDocId/chat-published";
import SlidesPage from "pages/word-docs/:wordDocId/slides";
import SlideDocPage from "pages/slide-docs/:slideDocId";
import QuickChatPage from "pages/quick-chat";
import LibraryPage from "pages/library";
import SlideDocSlidePage from "pages/slide-docs/:slideDocId/slides/:slideIndex";
import KnowledgeConfigsPage from "pages/knowledge-configs";

import DashboardPage from "pages/dashboards/:wordDocId";
import DashboardPageSource from "pages/dashboards/:wordDocId/source";
import TrialDashboardPage from "pages/trial-dashboards/:wordDocId";
import TrialDashboardPageSource from "pages/trial-dashboards/:wordDocId/source";
import ResolvePage from "pages/flow-dashboard-templates/:templateId/resolve";
import FlowTemplatesPage from "pages/flow-templates";
import IntroPage from "pages/intro";
import ProfilePageNew from "pages/profile-new";
import ProfilePagePlan from "pages/profile-new/plan";
import ProfilePageBilling from "pages/profile-new/billing";
import ProfilePageUsage from "pages/profile-new/usage";
import VerifyAccountPage from "pages/verify-my-account";
import QuickChatStreamPage from "pages/quick-chat-stream";
import HomePageNew from "pages/home-new";
import WordDocPageNew from "pages/word-docs/:wordDocId/new";
import LoginPageNew from "pages/login-new";
import SignupPage from "pages/signup";
import { useEffect } from "react";
import {
  decodeStoredJwt,
  isStoredTokenInvalid,
} from "api/services/authenticationService";
import { isEmpty } from "lodash";
import { setLoginTokenInClientStorage } from "utils/auth-utils";
import ProfilePageTeams from "pages/profile-new/teams";
import ApiPage from "pages/api";
import ApiDocsPage from "pages/api-docs";
import ChatDocPage from "pages/chat-docs/:wordDocId";
import AdminBlogsPage from "pages/admin/blogs";
import AdminPageVisitsPage from "pages/admin/page-visits";
import WordDocPageNewShape from "pages/word-docs/:wordDocId/new-shape";
import LoginPageV2 from "pages/login-v2";
import WordDocPagePerf from "pages/word-docs/:wordDocId/perf";
import SignupPageNew from "pages/signup-new";
import LoginMyAccountPage from "pages/login-my-account";
import TermsPage from "pages/terms";
import ProfilePageInvoices from "pages/profile-new/invoices";

import PrivacyPage from "pages/privacy-policy";
import AppDashboardPage from "pages/apps/dashboard";
import CompareModelsPage from "pages/compare-models";
import AppRecordPage from "pages/apps/:pipelineConfigId";
import SequencesPage from "pages/apps/sequences";
import AppPageRecords from "pages/apps/records";
import AppPageHome from "pages/apps/home";
import SequencePage from "pages/apps/:sequenceId";
import AppSearchPage from "pages/apps/:pipelineConfigId/search";
import AppSettingsPage from "pages/apps/:pipelineConfigId/settings";
import ModelSettingsPage from "pages/apps/:pipelineConfigId/model-settings";
import ProfilePageIntegrationsOld from "pages/profile-new/integrations-old";
import ProfilePageIntegrations from "pages/profile-new/integrations";
import WordDocPageRefactor from "pages/word-docs/:wordDocId/refactor";
import OcrPage from "pages/files/:fileId/new";
import AppChatPage from "pages/apps/:pipelineConfigId/chat";
import ModelTrainingPage from "pages/model-training";
import PipelineSettingsPage from "pages/apps/:pipelineConfigId/settings-new";
import CreateAppPage from "pages/apps/create";
import ChatDocPageNew from "pages/chat-docs-new/wordDocId";
import AppChatPageNew from "pages/apps/:pipelineConfigId/chat-new";
import ChatPage from "pages/chat-new";
import AppPageConfigurable from "pages/apps-new";
import SequencePageNew from "pages/apps/:pipelineConfigId/sequences/:sequenceId";
import ApiTokensPage from "pages/api-tokens";
import EndpointDocPage from "pages/endpoint-doc";
import LandingPage from "pages/index-redirect";

const PUBLIC_ROUTES = [
  "/login",
  "/signup",
  "/signup-old",
  "/verify-my-account",
  "/chat",
  "/login-my-account",
  "/terms",
  "/privacy-policy",
  "/files/:fileId",
  "/",
  // "/profile-new",
  // "/profile/teams",
  // "/profile/plan",
  // "/profile/billing",
  // "/profile/usage",
  // "/profile/integrations",
];

const doesPathnameMatchRoute = (pathname, route) => {
  if (route === pathname) {
    return true;
  }

  if (route.includes(":")) {
    const routeParts = route.split("/");
    const pathnameParts = pathname.split("/");

    if (routeParts.length !== pathnameParts.length) {
      return false;
    }

    for (let i = 0; i < routeParts.length; i++) {
      if (routeParts[i].startsWith(":")) {
        continue;
      }

      if (routeParts[i] !== pathnameParts[i]) {
        return false;
      }
    }

    return true;
  }

  return false;
};

const isPathnamePublic = pathname => {
  if (pathname?.includes("/login")) {
    return true;
  }
  return PUBLIC_ROUTES.some(route => doesPathnameMatchRoute(pathname, route));
};

const Root = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const tokenObj = decodeStoredJwt();

  useEffect(() => {
    if (isPathnamePublic(location?.pathname)) {
      return;
    }

    if (isStoredTokenInvalid()) {
      navigate("/login");
      return;
    }

    const tokenObj = decodeStoredJwt();
    if (isEmpty(tokenObj)) {
      navigate("/chat");
      return;
    }

    const isTrialUser = tokenObj?.authorities?.includes("ROLE_TRIAL_USER");
    if (isTrialUser) {
      navigate("/flow-home");
      return;
    }
  }, [JSON.stringify(location)]);

  if (isEmpty(tokenObj) && !isPathnamePublic(location?.pathname)) {
    return null;
  }

  return <Outlet />;
};

const PageComponentOnRoute = () => {
  return (
    <Routes>
      <Route path="" element={<Root />}>
        {/* <Route path="/login-v2" element={<LoginPageV2 />} /> */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/login" element={<LoginPageV2 />} />
        <Route path="/login/:providerInUrl" element={<LoginPageV2 />} />
        <Route path="/login-old" element={<LoginPageNew />} />
        <Route path="/signup-old" element={<SignupPage />} />
        <Route path="/signup" element={<SignupPageNew />} />
        <Route path="/one-time-code" element={<OneTimeCodePage />} />
        <Route path="/files/:fileId/old" element={<FilePageNew />} />
        <Route path="/file/:fileId/old" element={<FilePageNew />} />
        <Route path="/files/:fileId" element={<OcrPage />} />
        <Route path="/audit" element={<AuditPage />} />
        <Route path="/annotations-config" element={<AnnotationsConfigPage />} />
        <Route path="/search" element={<Search />} />
        <Route path="/search-emails" element={<SearchEmailsPage />} />
        <Route path="/start-search" element={<StartSearchPage />} />
        <Route path="/table-configs" element={<TableConfigsPage />} />
        <Route path="/table-configs/new" element={<CreateTableConfigPage />} />
        <Route
          path="/table-configs/:tableConfigId/edit"
          element={<EditTableConfigPage />}
        />
        <Route
          path="/table-configs/:tableConfigId/results"
          element={<TableConfigResultsPage />}
        />
        <Route path="/tags" element={<TagsPage />} />
        <Route path="/tags/:tagId" element={<TagPage />} />
        <Route path="/chat" element={<ChatPage />} />
        <Route path="/excel-views" element={<ExcelViewsPage />} />
        <Route path="/excel-views/:excelModelId" element={<ExcelViewPage />} />
        <Route
          path="/excel-web-views/:excelModelId"
          element={<ExcelWebViewPage />}
        />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/excel-files/:fileId" element={<ExcelFileOcrPage />} />
        <Route path="/word-docs/:wordDocId/old" element={<WordDocPage />} />
        <Route path="/word-docs/:wordDocId/slides" element={<SlidesPage />} />
        <Route
          path="/word-docs/:wordDocId/chat"
          element={<ChatWordDocPage />}
        />
        <Route
          path="/word-docs/:wordDocId/chat-published"
          element={<ChatWordDocPublishedPage />}
        />
        <Route path="/word-docs/:wordDocId/old" element={<WordDocPageNew />} />
        <Route path="/email-files/:emailFileId" element={<EmailFilePage />} />
        <Route path="/web-pages/:webPageId" element={<WebPagePage />} />
        <Route path="/word-docs" element={<WordDocsPage />} />
        <Route path="/crawler" element={<CrawlerPage />} />
        <Route path="/source-files" element={<SourceFilesPage />} />
        <Route path="/working-files" element={<WorkingFilesPage />} />
        {/* <Route path="/working-files-new" element={<WorkingFilesPageNew />} /> */}
        <Route path="/template-files" element={<TemplateFilesPage />} />
        <Route path="/template-files/new" element={<NewTemplateFilePage />} />
        <Route
          path="/template-files/:wordDocId"
          element={<TemplateFileEditPage />}
        />
        <Route
          path="/template-dashboards/:templateFileId"
          element={<TemplateDahsboardPage />}
        />
        <Route path="/profile/source" element={<ProfileSourcePage />} />
        <Route path="/profile/space" element={<ProfileSpacePage />} />
        <Route path="/profile/pipelines" element={<ProfilePipelinesPage />} />
        <Route path="/dashboards/:wordDocId" element={<DashboardPage />} />
        <Route
          path="/dashboards/:wordDocId/source"
          element={<DashboardPageSource />}
        />
        <Route
          path="/flow-dashboard-templates/:templateId/resolve"
          element={<ResolvePage />}
        />
        <Route
          path="/trial-dashboards/:wordDocId"
          element={<TrialDashboardPage />}
        />
        <Route
          path="/trial-dashboards/:wordDocId/source"
          element={<TrialDashboardPageSource />}
        />
        <Route path="/knowledge-configs" element={<KnowledgeConfigsPage />} />
        <Route path="/slide-docs/:slideDocId" element={<SlideDocPage />} />
        <Route
          path="/slide-docs/:slideDocId/slides/:slideIndex"
          element={<SlideDocSlidePage />}
        />
        <Route path="/quick-chat" element={<QuickChatPage />} />\
        <Route path="/quick-chat-stream" element={<QuickChatStreamPage />} />
        <Route path="/flow-home" element={<HomePageNew />} />
        <Route path="/library" element={<LibraryPage />} />
        <Route path="/flow-templates" element={<FlowTemplatesPage />} />
        <Route path={"/intro"} element={<IntroPage />} />
        <Route path={"/profile-new"} element={<ProfilePageNew />} />
        <Route path={"/profile/plan"} element={<ProfilePagePlan />} />
        <Route path={"/profile/billing"} element={<ProfilePageBilling />} />
        <Route path={"/profile/invoices"} element={<ProfilePageInvoices />} />
        <Route path={"/profile/usage"} element={<ProfilePageUsage />} />
        <Route path={"/profile/teams"} element={<ProfilePageTeams />} />
        <Route
          path="/profile/integrations-old"
          element={<ProfilePageIntegrationsOld />}
        />
        <Route
          path="/profile/integrations-old/:providerInUrl"
          element={<ProfilePageIntegrationsOld />}
        />
        <Route
          path="/profile/integrations"
          element={<ProfilePageIntegrations />}
        />
        <Route
          path="/profile/integrations/:providerInUrl"
          element={<ProfilePageIntegrations />}
        />
        <Route path="/verify-my-account" element={<VerifyAccountPage />} />
        <Route path="/api" element={<ApiPage />} />
        <Route path="/api-docs" element={<ApiDocsPage />} />
        <Route path="/api-tokens" element={<ApiTokensPage />} />
        <Route path="/endpoint-doc" element={<EndpointDocPage />} />
        <Route path="/chat-docs/:wordDocId" element={<ChatDocPageNew />} />
        <Route path="/chat-docs-new/:wordDocId" element={<ChatDocPageNew />} />
        <Route path="/chat-docs-old/:wordDocId" element={<ChatDocPage />} />
        <Route path="/admin/blogs" element={<AdminBlogsPage />} />
        <Route path="/admin/page-visits" element={<AdminPageVisitsPage />} />
        <Route
          path="/word-docs/:wordDocId/new-shape"
          element={<WordDocPageNewShape />}
        />
        <Route
          path="/word-docs/:wordDocId/perf"
          element={<WordDocPagePerf />}
        />
        <Route path="/word-docs/:wordDocId" element={<WordDocPageRefactor />} />
        <Route path="/login-my-account" element={<LoginMyAccountPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
        <Route
          path="/apps/:pipelineConfigId/records/:selectedRecordIdStr"
          element={<AppRecordPage />}
        />
        <Route
          path="/apps-new/:pipelineConfigId/records/:selectedRecordIdStr"
          element={<AppRecordPage />}
        />
        <Route
          path="/apps/:pipelineConfigId/records"
          element={<AppPageRecords />}
        />
        <Route
          path="/apps-new/:pipelineConfigId/:slug"
          element={<AppPageConfigurable />}
        />
        <Route path="/apps/:pipelineConfigId/home" element={<AppPageHome />} />
        <Route
          path="/apps/:pipelineConfigId/settings"
          element={<PipelineSettingsPage />}
        />
        <Route
          path="/apps-new/:pipelineConfigId/settings"
          element={<PipelineSettingsPage />}
        />
        <Route path="/apps/create" element={<CreateAppPage />} />
        <Route
          path="/apps/:pipelineConfigId/settings-old"
          element={<AppSettingsPage />}
        />
        <Route
          path="/apps/:pipelineConfigId/sequences"
          element={<SequencesPage />}
        />
        <Route
          path="/apps-new/:pipelineConfigId/sequences"
          element={<SequencesPage />}
        />
        <Route
          path="/apps/:pipelineConfigId/dashboard"
          element={<AppDashboardPage />}
        />
        <Route
          path="/apps/:pipelineConfigId/sequences-old/:sequenceId"
          element={<SequencePage />}
        />
        <Route
          path="/apps/:pipelineConfigId/sequences/:sequenceId"
          element={<SequencePageNew />}
        />
        <Route
          path="/apps-new/:pipelineConfigId/sequences/:sequenceId"
          element={<SequencePage />}
        />
        <Route
          path="/apps/:pipelineConfigId/search"
          element={<AppSearchPage />}
        />
        <Route
          path="/apps/:pipelineConfigId/chat"
          element={<AppChatPageNew />}
        />
        <Route
          path="/apps-new/:pipelineConfigId/chat"
          element={<AppChatPageNew />}
        />
        <Route path="/model-settings" element={<ModelSettingsPage />} />
        <Route path="/compare-models" element={<CompareModelsPage />} />
        <Route path="/model-training" element={<ModelTrainingPage />} />
      </Route>
    </Routes>
  );
};

export default PageComponentOnRoute;
