import {
  getJwtForUserFromOneTimeCode,
  postLogin,
  resetPassword,
} from "api/services/authenticationService";
import ButtonIcon from "components/ui/ButtonIcon";
import { BoltzbitLogo } from "components/ui/Icons";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { setLoginTokenInClientStorage } from "utils/auth-utils";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  justify-content: center;
  padding-top: 200px;

  /* seed */
  /* #e4edf9; */

  /* light bg */
  /* #f7f9fd; */

  /* strong bg */
  /* #cdd4e0; */

  background: linear-gradient(315deg, #f4f8fd 0%, #cdd4e0 60%, #cdd4e0 100%);
`;

const Frame = styled.div`
  padding: 40px;
  background-color: white;
  border-radius: 4px;

  display: grid;
  grid-template-columns: auto 1fr;

  column-gap: 0px;
  row-gap: 20px;
  height: max-content;
`;

const Title = styled.div`
  font-size: 36px;
  font-weight: 500;
  align-self: center;
  width: 250px;
  line-height: 1.2;
`;

const Input = styled.input`
  outline: none;
  font-family: "Montserrat";
  border-radius: 4px;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 12px 16px;
  font-size: 18px;
  width: 458px;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const ButtonPrimary = styled(ButtonIcon)`
  font-weight: 500;
  padding: 8px 20px;
`;

const ButtonSecondary = styled(ButtonIcon)`
  font-weight: 500;
  background-color: transparent;
`;

const LeftContent = styled.div`
  display: grid;
  gap: 20px;
  align-content: start;
  align-items: start;
  width: 420px;
`;

const RightContent = styled.div`
  margin-top: 62px;
  display: grid;
  justify-items: end;
`;

const ErrorMessage = styled.div`
  height: 120px;
  padding: 10px;
  justify-self: start;
  color: ${props => props.theme.color.error};
  width: 450px;
  line-height: 1.2;
`;

const Message = styled.div`
  width: 250px;
  line-height: 1.2;
`;

const Username = styled.div`
  width: max-content;
  font-size: 16px;
`;

const LoginPageNew = () => {
  const [text, setText] = useState("");
  const [searchParams] = useSearchParams();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const userName = searchParams.get("userName");
  const hasForgottenPassword =
    searchParams.get("hasForgottenPassword") === "true";
  const isTwoFactorRequired =
    searchParams.get("isTwoFactorRequired") === "true";

  useEffect(() => {
    setText("");
    setError("");
  }, [userName]);

  const doLogin = async () => {
    setIsLoading(true);
    const { data, error } = await postLogin({}, { userName, password: text });
    setIsLoading(false);
    setError(error);

    if (data?.isTwoFactorRequired) {
      window.location = `/login?userName=${userName}&isTwoFactorRequired=true`;
      return;
    }

    if (!error) {
      setLoginTokenInClientStorage(data.accessToken);
      navigate("/");
    }
  };

  const doResetPassword = async () => {
    setIsLoading(true);
    const { error } = await resetPassword(text);
    setIsLoading(false);
    setError(error);
    navigate(
      `/login?userName=${text}&message=We sent you an email with your new password`
    );
  };

  const doLoginTwoFactor = async () => {
    setIsLoading(true);
    const { data, error } = await getJwtForUserFromOneTimeCode(
      userName,
      text?.trim()
    );
    setIsLoading(false);
    setError(error);
    if (!error) {
      setLoginTokenInClientStorage(data.accessToken);
      navigate("/");
    }
  };

  let leftMessage = searchParams?.get("message") || "";
  let title = "Sign in";
  let placeholder = "Email";
  let onClickNext = () => navigate(`/login?userName=${text}`);
  let secondaryButton = null;
  //  (
  //   <ButtonSecondary onClick={() => navigate(`/signup`)}>
  //     Sign up
  //   </ButtonSecondary>
  // );
  let inputType = "text";

  if (userName) {
    inputType = "password";
    placeholder = "Password";
    onClickNext = doLogin;
    secondaryButton = (
      <ButtonSecondary
        onClick={() => navigate(`/login?hasForgottenPassword=true`)}
      >
        Forgot password?
      </ButtonSecondary>
    );
  }

  if (hasForgottenPassword) {
    title = "Reset password";
    placeholder = "Email";
    onClickNext = doResetPassword;
    secondaryButton = (
      // eslint-disable-next-line no-restricted-globals
      <ButtonSecondary onClick={() => history.back()}>Back</ButtonSecondary>
    );
  }

  if (isTwoFactorRequired) {
    inputType = "text";
    placeholder = "Enter code";
    leftMessage = "We sent you a code to your email";
    onClickNext = doLoginTwoFactor;
  }

  let greetngPrefix = "Hello, ";
  if (userName?.includes("@")) {
    greetngPrefix = "";
  }

  return (
    <Container>
      <Frame>
        <LeftContent>
          <Link
            to="/login"
            style={{
              width: "max-content",
            }}
          >
            <BoltzbitLogo
              style={{
                height: "42px",
                //  fill: "url(#SvgGradientPurpleToBlue)"
              }}
            />
          </Link>
          <Title>{title}</Title>
          {userName && (
            <Username>
              {greetngPrefix}
              {userName}
            </Username>
          )}
          <Message>{leftMessage}</Message>
        </LeftContent>

        <RightContent>
          <Input
            type={inputType}
            value={text}
            onChange={e => setText(e.target.value)}
            onKeyDown={e => {
              if (e.key === "Enter") {
                onClickNext();
              }
            }}
            placeholder={placeholder}
          />
          <ErrorMessage>{error && error?.message}</ErrorMessage>
          <div style={{ display: "flex", justifyItems: "end", gap: "10px" }}>
            {secondaryButton}
            <ButtonPrimary
              onClick={onClickNext}
              isActive
              isDisabled={!text || isLoading}
            >
              Next
            </ButtonPrimary>
          </div>
        </RightContent>
      </Frame>
    </Container>
  );
};

export default LoginPageNew;
