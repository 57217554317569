import { Editor } from "@monaco-editor/react";
import { postAndStreamResponse } from "api/api-http-methods";
import { Gap } from "components/Layout";
import ButtonWord from "components/ui/ButtonWord";
import { PencilIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import { get } from "lodash";
import { useState } from "react";
import ReactQuill from "react-quill";
import styled from "styled-components";

const STEP_0_INPUT_FIELDS = [
  {
    label: "Sequence trigger",
    type: "select",
    path: "type",
    options: [
      {
        label: "Record created",
        value: "record-created",
      },
      {
        label: "Record updated",
        value: "record-updated",
      },
      {
        label: "Manual trigger",
        value: "manually-triggered",
      },
      {
        label: "Upload document",
        value: "document-uploaded",
      },
    ],
  },
];

const STEP_INPUT_FIELDS = [
  {
    type: "text",
    path: "name",
    label: "Name",
  },
  {
    label: "Action type",
    type: "select",
    path: "type",
    options: [
      {
        label: "Send email",
        value: "email",
      },
      {
        label: "Generate word doc",
        value: "generate_word_doc",
      },
      {
        label: "Web Search",
        value: "internet",
      },
      {
        label: "Code",
        value: "code",
      },
    ],
  },
];

const ACTION_TYPE_TO_INPUT_FIELDS = {
  email: [
    {
      type: "number",
      path: "action.offsetMS",
      label: "Delay (milliseconds)",
    },
    { label: "Recipient", type: "text", path: "function.recipients" },
    { label: "Subject", type: "text", path: "function.subject" },
    {
      label: "Template",
      type: "html-editor-prompted",
      path: "function.template",
    },
  ],
  generate_word_doc: [
    { label: "Doc Prompt", type: "textarea-prompted", path: "function.prompt" },
  ],
  internet: [
    { label: "Search query", type: "textarea", path: "function.template" },
  ],
  code: [
    { label: "Code", type: "code-editor-prompted", path: "function.template" },
  ],
};

const ModalContent = styled.div`
  padding: 20px;
  width: 600px;
  height: 400px;
  overflow: auto;
`;

const BoldDiv = styled.span`
  font-weight: 600;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  padding: 8px;
`;

const FormFields = styled.div`
  padding: 20px;
`;

const Select = styled.select`
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 14px;
  font-weight: 500;
`;

const TextArea = styled.textarea`
  height: 200px;
  resize: none;
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 14px;
  font-weight: 500;
`;

const StyledInput = styled.input`
  height: 52px;
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 14px;
  font-weight: 500;

  :disabled {
    opacity: 0.6;
  }
`;

const StyledPencilIcon = styled(PencilIcon)`
  height: 14px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const QuillContainer = styled.div`
  border: 1px solid #eaeaea;
  .ql-editor {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    height: 200px;
  }
`;

const StyledEditor = styled(Editor)`
  height: 300px;
  border: 1px solid ${props => props.theme.color.closer1};
`;

let BASE_URL = "";
if (process.env.REACT_APP_IS_LOCAL_DEV === "true") {
  // BASE_URL = "https://ocr.boltztest.com";
  BASE_URL = "https://flow.boltzbit.com";
}
let chatQueriesUrl = "/bz-api/v1/ai/streamed-chat-queries";

const FieldInput = ({
  value = "",
  onChangeValue = newValue => {},
  type = "",
  options = [],
}) => {
  const [prompt, setPrompt] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);

  const doGenerateDraft = async () => {
    setIsGenerating(true);

    const payload = {
      cursor: { blockIndex: 0, letterIndex: prompt?.length },
      blocks: [{ text: prompt }],
      sources: [],
    };
    const { error } = await postAndStreamResponse({
      url: `${BASE_URL}${chatQueriesUrl}`,
      reqBody: payload,
      abortController: {},
      onDataReceived: data => {
        if (!data?.blocks) {
          return;
        }
        let newBody = data?.blocks?.[1]?.text;
        newBody = newBody?.replaceAll(/\n/g, "<br>");

        onChangeValue(newBody);
      },
    });
    setIsGenerating(false);
  };

  if (type === "textarea") {
    return (
      <TextArea value={value} onChange={e => onChangeValue(e.target.value)} />
    );
  }

  if (type?.includes("-prompted")) {
    let inputElement = (
      <TextArea value={value} onChange={e => onChangeValue(e.target.value)} />
    );
    if (type === "html-editor-prompted") {
      inputElement = (
        <QuillContainer key={isGenerating}>
          <ReactQuill
            theme="snow"
            defaultValue={value || ""}
            onChange={newValue => onChangeValue(newValue)}
          />
        </QuillContainer>
      );
    }
    if (type === "code-editor-prompted") {
      inputElement = (
        <StyledEditor
          value={value}
          onChange={newValue => onChangeValue(newValue)}
        />
      );
    }

    return (
      <div>
        <div
          style={{ display: "grid", gridTemplateColumns: "1fr auto", gap: 10 }}
        >
          <StyledInput
            style={{ height: 36 }}
            placeholder="Prompt"
            value={prompt}
            onChange={e => setPrompt(e.target.value)}
          />
          <ButtonWord
            onClick={doGenerateDraft}
            disabled={isGenerating}
            isPrimary
          >
            Generate
          </ButtonWord>
        </div>
        <Gap height="10px" />
        {inputElement}
      </div>
    );
  }

  if (type === "select") {
    return (
      <Select value={value} onChange={e => onChangeValue(e.target.value)}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    );
  }

  return (
    <StyledInput
      value={value}
      type={type || "text"}
      onChange={e => {
        if (type === "number") {
          onChangeValue(parseInt(e.target.value));
          return;
        }
        onChangeValue(e.target.value);
      }}
    />
  );
};

const ConfigureStepModal = ({
  pipelineConfig = {},
  selectedStep = {},
  onChangeSelectedStep = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const emailColumns =
    pipelineConfig?.sourceTables?.[0]?.columns?.filter(
      column => column?.formatType === "Email"
    ) || [];

  const renderInputComponent = field => {
    if (field?.path === "function.recipients") {
      return (
        <>
          <BoldDiv>{field.label}</BoldDiv>
          <Gap height="8px" />
          <Select
            value={get(selectedStep, field.path)?.[0]}
            onChange={e => {
              const newRecipients = [e.target.value];
              onChangeSelectedStep(field.path, newRecipients);
            }}
          >
            <option value="">-</option>
            {emailColumns?.map(column => (
              <option value={column?.dbName}>{column?.displayName}</option>
            ))}
          </Select>
          <Gap height="20px" />
        </>
      );
    }

    return (
      <>
        <BoldDiv>{field.label}</BoldDiv>
        <Gap height="8px" />
        <FieldInput
          value={get(selectedStep, field.path)}
          onChangeValue={value => onChangeSelectedStep(field.path, value)}
          type={field.type}
          options={field.options}
        />
        <Gap height="20px" />
      </>
    );
  };

  return (
    <>
      <StyledPencilIcon onClick={() => setIsOpen(true)} />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Title>Configure step</Title>
          <FormFields>
            {selectedStep?.id === "step0" &&
              STEP_0_INPUT_FIELDS.map(renderInputComponent)}
            {selectedStep?.id !== "step0" &&
              STEP_INPUT_FIELDS.map(renderInputComponent)}
            {ACTION_TYPE_TO_INPUT_FIELDS[selectedStep?.type]?.map(
              renderInputComponent
            )}
          </FormFields>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfigureStepModal;
