import useClickOutside from "hooks/useClickOutside";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { uuidv4 } from "utils/common";

const Tip = styled.div`
  background-color: ${props => props.theme.color.furthest};
  z-index: 10001;
  visibility: hidden;
  border-radius: 10px;
  /* box-shadow: ${props => props.theme.shadow}; */
`;

const Container = styled.div`
  width: max-content;
  position: relative;
`;

const TooltipClickNew = ({
  style = {},
  tipStyle = {},
  className = "",
  children,
  tipContent = "Tip Content",
  position = "bottomLeft",
}) => {
  const [popOverId] = useState(uuidv4());
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const openPopoverId = searchParams.get("openPopoverId");
  const isOpen = openPopoverId === popOverId;

  const ref = useRef(null);
  const tipRef = useRef(null);
  const rect = ref.current?.getBoundingClientRect();
  const tipRect = tipRef.current?.getBoundingClientRect();

  const open = () => {
    searchParams.set("openPopoverId", popOverId);
    navigate({ search: searchParams.toString() });
  };

  const close = () => {
    searchParams.delete("openPopoverId");
    navigate({ search: searchParams.toString() });
  };

  const toggle = () => {
    isOpen ? close() : open();
  };

  useClickOutside(ref, () => {
    if (isOpen) {
      close();
    }
  });

  let left = rect?.right ?? 0;
  if (position === "bottom") {
    left = rect?.right - tipRect?.width;
  }
  if (position === "bottomLeft") {
    left = rect?.left;
  }

  return (
    <Container ref={ref} className={className} style={style}>
      <div
        style={{ width: "max-content" }}
        onClick={e => {
          e.stopPropagation();
          toggle();
        }}
      >
        {children}
      </div>
      <Tip
        ref={tipRef}
        style={{
          position: "fixed",
          top: rect?.top + rect?.height + 4 ?? 0,
          left,
          visibility: openPopoverId === popOverId ? "visible" : "hidden",
          ...tipStyle,
          backgroundColor: "white",
        }}
        onClick={e => {
          e.stopPropagation();
          close();
        }}
      >
        {tipContent}
      </Tip>
    </Container>
  );
};

export default TooltipClickNew;
