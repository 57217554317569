import styled from "styled-components";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import {
  getLoggedInUserName,
  getUserGroups,
  postUserGroups,
} from "api/services/authenticationService";
import NavWithTabsWithArrows from "components/ui/NavWithTabsWithArrows";
import TooltipClick from "components/ui/TooltipClick";
import Tooltip from "components/ui/Tooltip";
import CreateUserGroupModalTrigger from "components/CreateUserGroupModalTrigger";
import AddMemberToUserGroupModalTrigger from "components/AddMemberToUserGroupModalTrigger";
import ConfirmationModalTrigger from "components/ConfirmationModalTrigger";
import FileSideBar from "components/FileSideBar";
import LayoutNew from "components/LayoutNew";
import { TrashIcon } from "components/ui/Icons";
import { Gap } from "components/Layout";
import { CrossInCircleIcon } from "components/IconsNew";
import {
  deleteTeam,
  getOrganization,
  getTeams,
  postRemoveTeamMember,
} from "api/backend/authenticationServiceEndpoints";
import { CircularProgress } from "@material-ui/core";

const Container = styled.div`
  padding: 80px 120px;
  background-color: #e6e6e6;
  height: 100%;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
`;

const Table = styled.table`
  width: max-content;
  font-size: 16px;
  ${props => props.isDisabled && "opacity: 0.5;"}
  margin: 10px 15px;
`;

const Td = styled.td`
  padding: 8px 0;
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
`;

const Tr = styled.tr`
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const TooltipOption = styled.div`
  cursor: pointer;
  display: flex;
  gap: 20px;
  padding: 8px 12px;
  align-items: center;
  width: 200px;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;

  :hover {
    opacity: 0.6;
  }

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const Trigger = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-weight: 700;
  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.color.closer1};
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
`;

const CardTitle = styled.div`
  padding: 15px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 210px;
`;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
`;

const StyledTrashIcon = styled(TrashIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const StyledCrossInCircleIcon = styled(CrossInCircleIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const COLUMNS = [
  {
    name: "userId",
    label: "Username",
  },
];

const TeamCard = ({ team, doPopulateTeams }) => {
  const doRemoveMember = async userId => {
    await postRemoveTeamMember(team?.id, {}, { userId });
    doPopulateTeams();
  };

  const doDeleteTeam = async teamId => {
    await deleteTeam(teamId);
    doPopulateTeams();
  };

  return (
    <Card>
      <CardTitle>
        {team?.name}
        <StyledTrashIcon height="14px" onClick={() => doDeleteTeam(team?.id)} />
      </CardTitle>
      <Table>
        <tbody>
          {team?.members?.map(member => (
            <Tr>
              <Td>{member?.userId}</Td>
              <Td style={{ width: "max-content" }}>
                <StyledCrossInCircleIcon
                  height="14px"
                  onClick={() => doRemoveMember(member?.userId)}
                />
              </Td>
            </Tr>
          ))}
        </tbody>
      </Table>

      <div style={{ padding: 20, paddingTop: 0 }}>
        <AddMemberToUserGroupModalTrigger
          team={team}
          onCreationSuccess={doPopulateTeams}
        />
      </div>
    </Card>
  );
};

const ProfilePageTeams = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [org, setOrg] = useState(null);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    doPopulateOrganisation();
  }, []);

  useEffect(() => {
    doPopulateTeams();
  }, [org?.id]);

  const doPopulateTeams = async () => {
    const { data } = await getTeams({ organizationId: org?.id });
    setTeams(data || []);
    setIsLoading(false);
  };

  const doPopulateOrganisation = async () => {
    setIsLoading(true);
    const userId = getLoggedInUserName();
    const { data } = await getOrganization({ userId });
    setOrg(data);
  };

  // return (
  //   <LayoutNew>
  //     <Container>
  //       <Title>Teams</Title>
  //       <BoldSpan>
  //         Team management is only available in the “Teams” plan
  //       </BoldSpan>
  //       <ButtonWord
  //         isPrimary
  //         style={{ width: 200, height: 40 }}
  //         onClick={() => {
  //           // go to /profile/plan, while keeping search params
  //           navigate(`/profile/plan?${searchParams.toString()}`);
  //         }}
  //       >
  //         See plans
  //       </ButtonWord>
  //     </Container>
  //   </LayoutNew>
  // );

  let bottomContent = (
    <CardsContainer>
      {teams?.map(team => (
        <TeamCard team={team} doPopulateTeams={doPopulateTeams} />
      ))}
    </CardsContainer>
  );
  if (isLoading) {
    bottomContent = (
      <div>
        <CircularProgress
          thickness={12}
          style={{ color: "#0191ff" }}
          size={18}
        />
      </div>
    );
  }

  return (
    <LayoutNew>
      <Container>
        <Title>Manage teams</Title>
        <Gap />
        <CreateUserGroupModalTrigger
          onCreationSuccess={doPopulateTeams}
          organizationId={org?.id}
        />
        <Gap />
        {bottomContent}
      </Container>
    </LayoutNew>
  );
};

export default ProfilePageTeams;
