import {
  getPipelineConfig,
  getSigmasV2,
  putPipelineConfig,
  putSigmasV2,
} from "api/backend/fileSystemEndpoints";
import { getSequences } from "api/backend/projectServiceEndpoints";
import AddSigmaRecordModalNew from "components/AddSigmaRecordModalNew";
import {
  CodingIcon,
  DocSearchIcon,
  ListIcon,
  TableGridIcon,
} from "components/IconsNew";
import { Gap } from "components/Layout";
import LayoutApp from "components/LayoutApp";
import LayoutAppConfigurable from "components/LayoutAppConfigurable";
import ButtonWord from "components/ui/ButtonWord";
import { CrossIcon, PlusIcon } from "components/ui/Icons";
import { uniq } from "lodash";
import { COLOR1, COLOR2 } from "pages/login-v2";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { safeFormat } from "utils/common";

const Container = styled.div`
  white-space: pre-wrap;
  display: grid;
  height: 100%;
  overflow: hidden;
  align-content: start;
  padding: 120px;
  padding-top: 80px;
  background: linear-gradient(180deg, #f3f5f7 0%, #f2f2f2 100%);
`;

const Cards = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  overflow: auto;
`;

const GroupCard = styled.div`
  position: relative;
  background-color: #ffffff;
  padding: 12px 16px;
  border-radius: 10px;
  height: max-content;
  display: grid;
  align-content: start;
  grid-template-columns: auto auto;
  gap: 10px;
  width: 250px;
  height: 150px;
  cursor: pointer;

  :hover {
    box-shadow: 0px 1px 7px 1px #00000040;
    background: linear-gradient(176.39deg, ${COLOR2} 39.89%, ${COLOR1} 105.35%);
    color: white;
    * {
      color: white;
    }
    svg {
      fill: white;
    }
  }
`;

const GroupTitle = styled.div`
  grid-column: span 2;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  align-self: start;
  gap: 10px;
  color: ${props => props.theme.color.primary};
  svg {
    fill: ${props => props.theme.color.primary};
    height: 18px;
  }
`;

const TopBar = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
`;

const StyledInput = styled.input`
  padding: 8px 16px;
  width: 800px;
  font-weight: 400;
  border: 2px solid #e8ecef;
  border-radius: 12px;
  margin-top: 0px;
  font-size: 14px;
  font-family: "Montserrat";
  outline: none;
`;

const StyledSelect = styled.select`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px 8px;
  font-weight: 500;
  border-right: 16px solid transparent;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const ButtonWordIcon = styled(ButtonWord)`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  width: max-content;
  svg {
    fill: white;
  }
`;

const SwitchLinks = styled.div`
  background-color: #eaeaea;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 4px;
  border-radius: 14px;
`;

const SwitchLink = styled.div`
  color: white;
  background-color: ${props => (props.isActive ? "#d4d4d4" : "#eaeaea")};
  color: #434343;
  text-decoration: none;
  padding: 8px;
  border-radius: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    color: #000000;
  }
  svg {
    fill: #000000;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid #eaeaea;
  border-radius: 12px;
`;

const StyledCrossIcon = styled(CrossIcon)`
  opacity: 0.5;
  cursor: pointer;
  margin-right: 10px;
  :hover {
    opacity: 1;
  }
`;

const Td = styled.td`
  position: relative;
  /* border: 1px solid ${props => props.theme.color.closer1}; */
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  font-weight: 500;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
  svg {
    fill: black;
    height: 14px;
  }
`;

const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;
  padding: 8px;

  font-weight: 600;
  z-index: 1;
`;

const IconAndText = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Tr = styled.tr`
  cursor: pointer;
  :hover {
    background-color: #f3f5f7;
  }
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

export const SwitchLinksBar = ({ viewType = "", setViewType = () => {} }) => (
  <SwitchLinks>
    <SwitchLink
      onClick={() => setViewType("cards")}
      isActive={viewType === "cards"}
    >
      <TableGridIcon />
    </SwitchLink>
    <SwitchLink
      onClick={() => setViewType("table")}
      isActive={viewType === "table"}
    >
      <ListIcon />
    </SwitchLink>
  </SwitchLinks>
);

const SequencesPage = () => {
  const navigate = useNavigate();

  const { pipelineConfigId } = useParams();
  const [viewType, setViewType] = useState("cards");

  const [dateFilter, setDateFilter] = useState("");
  const [createdByFilter, setCreatedByFilter] = useState("");

  const [sequences, setSequences] = useState([]);
  const [query, setQuery] = useState("");

  const doPopulateSequences = async () => {
    const { data } = await getSequences({ pipelineId: pipelineConfigId });
    setSequences(data || []);
  };

  // FETCHING
  useEffect(() => {
    doPopulateSequences();
  }, [pipelineConfigId]);

  const onKeyDown = e => {
    if (e.key === "Enter") {
      navigate(`/apps/${pipelineConfigId}/search?query=${query}&type=SEQUENCE`);
    }
  };

  // sort by createdAt
  const sequencesMostRecentFirst = sequences
    .sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.createdAt);
    })
    ?.filter(record => {
      if (dateFilter === "today") {
        return (
          new Date(record.createdAt).toDateString() ===
          new Date().toDateString()
        );
      }
      if (dateFilter === "yesterday") {
        return (
          new Date(record.createdAt).toDateString() ===
          new Date(Date.now() - 86400000).toDateString()
        );
      }
      if (dateFilter === "last-7-days") {
        return new Date(record.createdAt) > new Date(Date.now() - 7 * 86400000);
      }
      return true;
    });

  const allAuthors = uniq(
    sequencesMostRecentFirst?.map(record => record?.createdBy)
  );

  const cardsView = (
    <Cards>
      {sequencesMostRecentFirst?.map(record => {
        return (
          <GroupCard
            onClick={() =>
              navigate(
                `${basePath}/${pipelineConfigId}/sequences/${record?.id}`
              )
            }
          >
            <GroupTitle>
              <CodingIcon />
              {record?.name || record.id}
            </GroupTitle>
          </GroupCard>
        );
      })}
    </Cards>
  );

  const tableView = (
    <table>
      <thead>
        <tr>
          <Th>Name</Th>
          <Th>Owner</Th>
          <Th>Creation date</Th>
        </tr>
      </thead>
      <tbody>
        {sequencesMostRecentFirst?.map(record => {
          return (
            <Tr
              onClick={() =>
                navigate(
                  `${basePath}/${pipelineConfigId}/sequences/${record?.id}`
                )
              }
            >
              <Td>
                <IconAndText>
                  <CodingIcon />
                  {record?.name || record.id}
                </IconAndText>
              </Td>
              <Td>
                <IconAndText>{record?.createdBy}</IconAndText>
              </Td>
              <Td style={{ fontWeight: 400 }}>
                {safeFormat(record?.createdAt, "dd MMM yyyy")}
              </Td>
            </Tr>
          );
        })}
      </tbody>
    </table>
  );

  let basePath = "/apps";
  let LayoutComponent = LayoutApp;
  if (window?.location?.pathname.includes("/apps-new/")) {
    basePath = "/apps-new";
    LayoutComponent = LayoutAppConfigurable;
  }

  return (
    <LayoutComponent>
      <Container>
        <Title style={{ display: "flex", gap: 20, alignItems: "center" }}>
          Your sequences
          <ButtonWordIcon
            onClick={() =>
              navigate(`${basePath}/${pipelineConfigId}/sequences/new`)
            }
            isPrimary
          >
            <PlusIcon /> New
          </ButtonWordIcon>
        </Title>
        <Gap />
        <StyledInput
          value={query}
          onChange={e => setQuery(e.target.value)}
          onKeyDown={onKeyDown}
          placeholder="Search sequences..."
        />
        <Gap height="80px" />

        <TopBar>
          <FilterContainer>
            <StyledSelect
              value={dateFilter}
              onChange={e => setDateFilter(e.target.value)}
            >
              <option value="">Creation date</option>
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="last-7-days">Last 7 days</option>
            </StyledSelect>
            {dateFilter !== "" && (
              <StyledCrossIcon onClick={() => setDateFilter("")} />
            )}
          </FilterContainer>
          <FilterContainer>
            <StyledSelect
              value={createdByFilter}
              onChange={e => setCreatedByFilter(e.target.value)}
            >
              <option>Created by</option>
              {allAuthors?.map(author => (
                <option value={author}>{author}</option>
              ))}
            </StyledSelect>
            {createdByFilter !== "" && (
              <StyledCrossIcon onClick={() => setCreatedByFilter("")} />
            )}
          </FilterContainer>
          <div />
          <SwitchLinksBar setViewType={setViewType} viewType={viewType} />
        </TopBar>
        <Gap height="10px" />
        {viewType === "cards" && cardsView}
        {viewType === "table" && tableView}
      </Container>
    </LayoutComponent>
  );
};

export default SequencesPage;
