import styled from "styled-components";
import LayoutNew from "components/LayoutNew";
import { Gap } from "components/Layout";
import { ENDPOINTS } from "components/ApiSidebar";
import { useSearchParams } from "react-router-dom";
import NavWithTabsNew from "components/ui/NavWithTabsNew";
import { useState, useEffect } from "react";
import { uniq } from "lodash";

import { Marked } from "marked";
import { markedHighlight } from "marked-highlight";
import hljs from "highlight.js";

const Container = styled.div`
  padding: 20px;
`;

const UrlTitle = styled.div`
  font-family: monospace;
  background-color: #e9e9e9;
  width: max-content;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 20px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
`;

const SwitchLinks = styled.div`
  background-color: #eaeaea;
  display: grid;
  grid-auto-flow: column;
  padding: 4px;
  border-radius: 14px;
  width: max-content;
`;

const SwitchLink = styled.div`
  color: white;
  background-color: ${props => (props.isActive ? "#d4d4d4" : "#eaeaea")};
  color: #434343;
  text-decoration: none;
  padding: 8px 20px;
  border-radius: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    color: #000000;
  }
  svg {
    fill: #000000;
  }
`;

const CodeSample = styled.div`
  border-radius: 4px;
  white-space: pre-wrap;
  code {
    line-height: 1.2;
    background-color: white;
    border-radius: 4px;
    font-family: monospace;
  }
`;

const MARKED = new Marked(
  markedHighlight({
    emptyLangClass: "hljs",
    langPrefix: "hljs language-",
    highlight(code, lang, info) {
      const language = hljs.getLanguage(lang) ? lang : "plaintext";
      return hljs.highlight(code, { language }).value;
    },
  })
);

const EndpointDocPage = () => {
  const [searchParams] = useSearchParams();
  const [selectedLang, setSelectedLang] = useState("cUrl");
  const [selectedTabId, setSelectedTabId] = useState(null);

  const endpoint = ENDPOINTS.find(
    endpoint => endpoint.path === searchParams.get("path")
  );

  const codeSamples = endpoint?.content?.["x-codeSamples"];
  const languages = uniq(codeSamples?.map(sample => sample?.lang));

  const tabs = codeSamples
    ?.filter(sample => sample?.lang === selectedLang)
    ?.map(sample => ({
      id: sample?.label,
      tableDisplayName: sample?.label,
    }));

  useEffect(() => {
    setSelectedTabId(tabs?.[0]?.id);
  }, [JSON.stringify(tabs)]);

  useEffect(() => {
    setSelectedLang(languages?.[0]);
  }, [JSON.stringify(languages)]);

  const codeStr =
    codeSamples?.find(sample => sample?.label === selectedTabId)?.source || "";

  const codeStrHtml = MARKED.parse(
    `\`\`\`${selectedLang}\n${codeStr}\n\`\`\`` || ""
  );

  return (
    <LayoutNew bgColor="linear-gradient(180deg, #F3F5F7 0%, #FDFDFD 100%);">
      <Container>
        <Title>{endpoint?.content?.description}</Title>
        <Gap />
        <UrlTitle>{endpoint?.path}</UrlTitle>
        <Gap />
        <SwitchLinks>
          {languages?.map(lang => (
            <SwitchLink
              key={lang}
              isActive={selectedLang === lang}
              onClick={() => setSelectedLang(lang)}
            >
              {lang}
            </SwitchLink>
          ))}
        </SwitchLinks>
        <Gap />
        <NavWithTabsNew
          selectedId={selectedTabId}
          tabs={tabs}
          onSelectTab={newId => setSelectedTabId(newId)}
        />
        <Gap />
        <CodeSample
          dangerouslySetInnerHTML={{
            __html: codeStrHtml,
          }}
        />
      </Container>
    </LayoutNew>
  );
};

export default EndpointDocPage;
