import { getFlowConfigs } from "api/backend/fileSystemEndpoints";
import { getLoggedInUserName } from "api/services/authenticationService";
import {
  AnalyticsIcon,
  ChatIcon,
  CodingIcon,
  DocSearchIcon,
  FunnelIcon,
  HomeIcon,
  SourcesIcon,
  SuiteIcon,
} from "components/IconsNew";
import { BoltzhubLogoInner } from "components/ui/Icons";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

export const SHOULD_USE_CONFIG_FROM_CODE = false;

export const ICON_MAP = {
  home: <HomeIcon />,
  analytics: <AnalyticsIcon />,
  logo: <BoltzhubLogoInner />,
  docSearch: <DocSearchIcon />,
  chat: <ChatIcon />,
  code: <CodingIcon />,
  suite: <SuiteIcon />,
  funnel: <FunnelIcon />,
  sources: <SourcesIcon />,
  logoInner: <BoltzhubLogoInner />,
  coding: <CodingIcon />,
};

export const useFlowConfig = () => {
  const [flowConfigs, setFlowConfigs] = useState([]);

  useEffect(() => {
    doPopulateFlowConfigs();
  }, []);

  const doPopulateFlowConfigs = async () => {
    const { data } = await getFlowConfigs();
    setFlowConfigs(data || []);
    const userFlowConfig = flowConfigs.find(
      config => config?.userId === getLoggedInUserName()
    );
    if (userFlowConfig?.config) {
      localStorage.setItem("flowConfig", userFlowConfig?.config);
    }
  };

  let config = {};
  const localStorageConfig = localStorage.getItem("flowConfig");
  if (localStorageConfig) {
    try {
      config = JSON.parse(localStorageConfig);
    } catch (e) {}
  }
  const userFlowConfig = flowConfigs.find(
    config => config?.userId === getLoggedInUserName()
  );
  if (userFlowConfig) {
    try {
      config = JSON.parse(userFlowConfig?.config);
    } catch (e) {}
  }
  if (isEmpty(config)) {
    config = GLOBAL_CONFIG;
  }

  return [config, flowConfigs];
};

export const EMPTY_PAGE = {
  components: [],
  layout: {},
  bgColor: "#ffffff",
};

export const GLOBAL_CONFIG = {
  navItems: [
    { icon: "chat", title: "Chat", type: "chat", filterType: "CHAT_DOC" },
    {
      icon: "suite",
      title: "Workspace",
      type: "word",
      comingSoonMsg: "Word Docs coming soon",
    },
    {
      icon: "funnel",
      title: "Apps",
      type: "pipelines",
      comingSoonMsg: "Automation apps coming soon",
    },
    {
      icon: "sources",
      title: "Sources",
      type: "sources",
    },
    {
      icon: "logoInner",
      title: "LLMs",
      url: "/model-settings",
    },
    {
      icon: "coding",
      title: "API",
      type: "api",
    },
  ],
};

/*

APP PAGE URL STRUCTURE:
/apps-new/:pipelineId/:slug

HARDCODED SLUGS:
chat         (will always give the chat page)
sequences    (will always give the sequences page)

*/
const APP_LAYOUT_CONFIG = {
  // Links on the sliding out sidebar
  navItems: [
    {
      slug: "home",
      title: "My home",
      icon: "home",
    },
    {
      slug: "chat",
      title: "My Chat",
      icon: "chat",
    },
    {
      slug: "sequences",
      title: "Programs",
      icon: "code",
    },
    {
      id: "more",
      type: "sub-navigation",
      title: "More",
      icon: "analytics",
      navItems: [
        {
          slug: "analytics-2",
          title: "Analytics 2",
          icon: "analytics",
        },
        {
          slug: "analytics-3",
          title: "Analytics 3",
          icon: "analytics",
        },
      ],
    },
  ],
  // This will be populated by drag n drop in FE. Persisted in local storage.
  slugToPage: {
    home: {
      components: [],
      layout: {},
      bgColor: "#ffffff",
    },
  },
  disableSideChat: false,
};

const APP_LAYOUT_CONFIG_2 = {
  // Links on the sliding out sidebar
  navItems: [
    {
      slug: "home",
      title: "My Stuff",
      icon: "home",
    },
    {
      id: "more",
      type: "sub-navigation",
      title: "More",
      icon: "analytics",
      navItems: [
        {
          slug: "analytics-2",
          title: "Analytics 2",
          icon: "analytics",
        },
        {
          slug: "analytics-3",
          title: "Analytics 3",
          icon: "analytics",
        },
      ],
    },
  ],
  // This will be populated by drag n drop in FE. Persisted in local storage.
  slugToPage: {
    home: EMPTY_PAGE,
  },
};

const NEW_CONFIG = {
  navItems: [
    {
      slug: "home",
      title: "Code Item",
      icon: "home",
    },
    {
      slug: "chat",
      title: "My Chat",
      icon: "chat",
    },
    {
      slug: "sequences",
      title: "Programs",
      icon: "code",
    },
    {
      id: "more",
      type: "sub-navigation",
      title: "More",
      icon: "analytics",
      navItems: [
        {
          slug: "analytics-2",
          title: "Analytics 2",
          icon: "analytics",
        },
        {
          slug: "analytics-3",
          title: "Analytics 3",
          icon: "analytics",
        },
      ],
    },
  ],
  slugToPage: {
    home: {
      components: [
        {
          id: "cdfbfe8e-78e9-49af-9ae8-bfdca60c72cf",
          type: "table",
          config: {},
        },
        {
          id: "9d0d9207-fe20-4694-8e94-41e82d7f5c28",
          type: "table",
          config: {},
        },
      ],
      layout: {
        "cdfbfe8e-78e9-49af-9ae8-bfdca60c72cf": {
          x: 3,
          y: 3,
          w: 20,
          h: 20,
        },
        "9d0d9207-fe20-4694-8e94-41e82d7f5c28": {
          x: 24,
          y: 3,
          w: 20,
          h: 40,
        },
      },
      bgColor: "#ffffff",
      width: 4000,
    },
    "analytics-2": {
      components: [
        {
          id: "05f73917-156a-44f3-b448-b3033270046d",
          type: "table",
          config: {},
        },
      ],
      layout: {
        "05f73917-156a-44f3-b448-b3033270046d": {
          x: 3,
          y: 3,
          w: 20,
          h: 20,
        },
      },
    },
  },
};

export const CUSTOM_APPS = [
  { pipelineId: "6769824b95816f4134469532", config: APP_LAYOUT_CONFIG },
  { pipelineId: "676569d826a4e71e51f41902", config: APP_LAYOUT_CONFIG_2 },
  {
    pipelineId: "67475519f1c070feb8d510b6",
    shouldUseGlobalSidebar: true,
    config: APP_LAYOUT_CONFIG_2,
  },
  {
    pipelineId: "677811974d27089d863fe7df",
    config: APP_LAYOUT_CONFIG,
  },
  {
    pipelineId: "677fc2af24f19e8f47291250",
    config: NEW_CONFIG,
  },
];
