import { Visibility } from "@material-ui/icons";
import { getFilePageResults } from "api/backend/filesEndpoints";
import ButtonWord from "components/ui/ButtonWord";
import Modal from "components/ui/Modal";
import ImgWithRectangleFlexible from "components/widgets/ImgWithRectangleFlexible";
import useResource from "hooks/useResource";
import { isNil, range, set } from "lodash";
import { AREA_TYPE_TO_COLOR } from "pages/files/:fileId/new";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const ModalContent = styled.div`
  position: relative;
  background-color: white;
  width: 800px;
  height: 600px;
  display: grid;
  align-content: start;
  white-space: pre-wrap;
  overflow: auto;
  background-color: #eaeaea;
  border-radius: 20px;

  @media (max-width: 1240px) {
    width: 90vw;
    height: auto;
    font-size: 14px;
    padding: 20px;
    line-height: 1.2;
  }
`;

const StickyTop = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding: 24px;

  @media (max-width: 1240px) {
    padding: 8px;
    font-size: 12px;
  }
`;

const FileLink = styled.a`
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.color.primary};
  background-color: white;
  padding: 8px;
  width: max-content;
`;

const FileName = styled.div`
  font-weight: 500;
  background-color: white;
  width: max-content;
`;

const StyledInput = styled.input`
  font-family: "Montserrat";
  border: 1px solid ${props => props.theme.color.closer1_5};
  border-radius: 0;
  outline: none;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const ZoomBtn = styled(ButtonWord)`
  padding: 0;
  width: 14px;
  height: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TriggerContainer = styled.div``;

const SmallButtonWord = styled(ButtonWord)`
  opacity: 0.5;
  font-size: 10px;
  line-height: 10px;
  padding: 4px;
  height: max-content;
  align-self: center;
  background-color: #eaeaea;
  color: black;
  :hover {
    background-color: black;
    color: white;
  }

  svg {
    height: 14px;
    width: 14px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
`;

export const DefaultTrigger = () => (
  <TriggerContainer
    className="trigger"
    onMouseDown={e => {
      e.preventDefault();
      e.stopPropagation();
    }}
  >
    <SmallButtonWord>
      <Visibility />
    </SmallButtonWord>
  </TriggerContainer>
);

const ImgWithRectangleFlexibleStyled = styled(ImgWithRectangleFlexible)``;

const PageResultContainer = styled.div`
  width: 720px;
  height: 990px;
  background-color: #d5d5d5;
`;

const PageNum = styled.div`
  padding: 20px;
  font-size: 20px;
  opacity: 0.2;
  font-weight: 700;
`;

const Img = styled.img`
  border: 1px solid ${props => props.theme.color.closer1};
`;

const ImgContainer = styled.div`
  position: relative;
`;

const Rect = styled.div`
  background-color: #00ff0044;
  opacity: ${props => (props.isInspecting ? 0.6 : 0)};
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

export const PageResult = ({
  fileId = "",
  pageNumber = null,
  scrollTop = 0,
  isAlwaysVisible = false,
  zoomFactor = 1,
  areBoxesVisible = false,
  onClickOutputItem = item => {},
}) => {
  const [searchParams] = useSearchParams();
  const ref = useRef(null);
  const imageRef = useRef(null);
  const [pageResults, setPageResults] = useState(null);
  const [imageElement, setImageElement] = useState(null);

  const rect = ref?.current?.getBoundingClientRect();
  const isVisible = (scrollTop > 0 && rect?.top < 600) || isAlwaysVisible;
  const isInspecting = searchParams.get("isInspecting") === "true";

  const doPopulatePageResults = async () => {
    const { data } = await getFilePageResults(fileId, pageNumber);
    setPageResults(data);
  };

  useEffect(() => {
    if (!searchParams?.get("pageNumber") || !ref.current) {
      return;
    }

    const queryPageNumber = parseInt(searchParams.get("pageNumber"));
    if (queryPageNumber === pageNumber) {
      ref.current.scrollIntoView();
    }
  }, [searchParams?.get("pageNumber"), ref?.current]);

  useEffect(() => {
    if (pageNumber !== parseInt(searchParams?.get("pageNumber"))) {
      return;
    }

    const outputId = parseInt(searchParams?.get("outputId"));
    const selectedItem = pageResults?.output?.find(
      item => item?.id === outputId
    );
    if (!selectedItem) {
      return;
    }

    onClickOutputItem(selectedItem);
  }, [
    searchParams?.get("pageNumber"),
    searchParams?.get("outputId"),
    pageNumber,
    pageResults?.output?.length,
  ]);

  useEffect(() => {
    if (!isVisible) {
      return;
    }
    if (!fileId || isNil(pageNumber) || !isNil(pageResults)) {
      return;
    }
    doPopulatePageResults();
  }, [isVisible, fileId, pageNumber, pageResults]);

  let containerStyle = {
    width: `calc(800px * ${zoomFactor})`,
  };
  let imgStyle = {
    width: "100%",
  };

  if (pageResults?.image) {
    return (
      <ImgContainer
        style={containerStyle}
        onClick={() => onClickOutputItem(null)}
      >
        {areBoxesVisible &&
          pageResults?.output?.map(outputItem => {
            let { x, y, w, h } = outputItem;

            const sfX = imageElement?.width / imageElement?.naturalWidth;
            const sfY = imageElement?.height / imageElement?.naturalHeight;

            x *= sfX;
            y *= sfY;
            w *= sfX;
            h *= sfY;

            return (
              <Rect
                isInspecting={isInspecting}
                key={outputItem.id}
                style={{
                  position: "absolute",
                  top: y,
                  left: x,
                  width: w,
                  height: h,
                  backgroundColor: `${
                    AREA_TYPE_TO_COLOR?.[outputItem.areaType]
                  }22`,
                }}
                onClick={e => {
                  e.stopPropagation();
                  onClickOutputItem(outputItem);
                }}
              />
            );
          })}
        <Img
          onLoad={e => {
            setImageElement(e.target);
          }}
          ref={imageRef}
          style={imgStyle}
          src={`data:image/png;base64,${pageResults?.image || ""}`}
          alt="page preview"
        />
      </ImgContainer>
    );
  }

  return (
    <PageResultContainer ref={ref}>
      <PageNum>{pageNumber + 1}</PageNum>
    </PageResultContainer>
  );
};

const FileViewModal = ({
  fileId,
  style = {},
  trigger = <DefaultTrigger />,
}) => {
  const [displayPageNumber, setDisplayPageNumber] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [zoomFactor, setZoomFactor] = useState(1);

  let fileUrl = "";
  let pageResultsUrl = "";
  if (isOpen) {
    pageResultsUrl = `/solutions/ocr/api/v1/files/${fileId}/page-results/${
      displayPageNumber - 1
    }`;
    fileUrl = `/solutions/ocr/api/v1/files/${fileId}`;
  }

  const [file] = useResource({
    url: fileUrl,
    isEnabled: isOpen,
  });
  const [pageResults] = useResource({ url: pageResultsUrl, isEnabled: isOpen });

  const isMobile = window?.screen?.width < 1240;

  if (isMobile) {
    return (
      <>
        <div
          style={style}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(true);
          }}
        >
          {trigger}
        </div>
        <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
          <ModalContent>File preview only available on desktop</ModalContent>
        </Modal>
      </>
    );
  }

  return (
    <>
      <div
        style={style}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        {trigger}
      </div>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent onScroll={e => setScrollTop(e?.target?.scrollTop)}>
          <StickyTop>
            <FileLink href={`/files/${file?.id}`} target="_blank">
              {file?.fileName}
            </FileLink>
          </StickyTop>
          <div
            style={{
              position: "sticky",
              top: 90,
              left: 0,
              display: "flex",
              gap: 4,
              paddingLeft: 10,
            }}
          >
            <ZoomBtn
              onClick={() => setZoomFactor(prev => Math.max(prev - 0.5, 1))}
            >
              -
            </ZoomBtn>
            <ZoomBtn
              onClick={() => setZoomFactor(prev => Math.min(prev + 0.5, 10))}
            >
              +
            </ZoomBtn>
          </div>
          {range(0, file?.numberOfPages).map(pageNumber => (
            <div style={{ margin: "12px 40px" }}>
              <PageResult
                zoomFactor={zoomFactor}
                isAlwaysVisible={pageNumber === 0}
                fileId={fileId}
                key={`${fileId}-${pageNumber}`}
                pageNumber={pageNumber}
                scrollTop={scrollTop}
              />
            </div>
          ))}
        </ModalContent>
      </Modal>
    </>
  );
};

export default FileViewModal;
