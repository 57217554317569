import { COLOR1, COLOR2 } from "pages/login-v2";
import styled from "styled-components";

// Uncomment to revert back to old button style
const ButtonWord = styled.button`
  padding: 0 12px;
  gap: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  background-color: #141718;
  color: #ffffff;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  :hover {
    color: #eaeaea;
  }

  ${props =>
    props.isPrimary &&
    `
    background: linear-gradient(176.39deg, ${COLOR2} 39.89%, ${COLOR1} 105.35%);,
    border-radius: 10px;
  `}

  :disabled {
    pointer-events: none;
    background: #e0e0e0;
  }
`;

const ButtonLinkContainer = styled.a`
  padding: 8px 12px;
  gap: 10px;
  border-radius: 8px;
  opacity: 0px;
  background-color: #f6f6f6;
  color: #1f1f1f !important;
  width: max-content;

  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-decoration: none;
  color: #1f1f1f;

  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border: 1px solid #e8e8e8;

  ${props => props?.disabled && "opacity: 0.5; pointer-events: none;"}

  :hover {
    background-color: #d8d8d8;
  }
`;

const BlueSquare = styled.div`
  width: 6px;
  height: 6px;
  background-color: #1473df;
`;

// Comment this whole component to revert back to old button style
// const ButtonWord = ({
//   target = "",
//   onClick = () => {},
//   disabled = false,
//   isPrimary = false,
//   style = {},
//   href,
//   children = [],
//   className = "",
// }) => (
//   <ButtonLinkContainer
//     className={className}
//     target={target}
//     onClick={onClick}
//     disabled={disabled}
//     href={href}
//     style={style}
//     isPrimary={isPrimary}
//   >
//     <BlueSquare />
//     {children}
//   </ButtonLinkContainer>
// );
// up to here

export default ButtonWord;
