import InputExpanding from "components/InputExpanding";
import { TrashIcon } from "components/ui/Icons";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: grid;
`;

const TabsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  width: 100%;
  overflow: auto;
  justify-content: start;
`;

const StyledTrashIcon = styled(TrashIcon)`
  opacity: 0;
  transition: opacity 0.2s;
`;

const TabOption = styled.div`
  width: max-content;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  padding: 15px 15px 15px 15px;
  line-height: 20px;
  font-weight: ${props => (props.isSelected ? "600" : "normal")};
  color: ${props =>
    props.isSelected ? props.theme.color.primary : props.theme.color.closest};
  border-bottom: ${props =>
    props.isSelected ? "2px solid " + props.theme.color.primary : "none"};

  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 8px;
  padding: 8px 15px;
  padding-left: 36px;

  svg {
    width: 14px;
    height: 14px;
    ${props => props.isSelected && "fill: " + props.theme.color.primary};
  }

  :hover {
    svg {
      opacity: 1;
      :hover {
        opacity: 0.5;
      }
    }
  }
`;

const StyledInputExpanding = styled(InputExpanding)`
  font-weight: 600;
`;

/*
tab = { id, tableDisplayName }
*/
const NavWithTabsNew = ({
  isEditable = false,
  className = "",
  tabs = [],
  selectedId = "",
  onSelectTab = newId => {},
  onChangeTabName = (id, newName) => {},
  onRemoveTab = id => {},
}) => {
  const [editingTabId, setEditingTabId] = useState(null);

  if (!tabs.length) {
    return null;
  }

  return (
    <Container className={className}>
      <TabsContainer>
        {tabs.map((tab, i) => {
          let tabContent = (
            <div onDoubleClick={() => setEditingTabId(tab?.id)}>
              {tab?.tableDisplayName || "New Table"}
            </div>
          );
          if (editingTabId === tab?.id && isEditable) {
            tabContent = (
              <StyledInputExpanding
                autoFocus
                minWidth={80}
                placeholder="Table name"
                value={tab?.tableDisplayName}
                onChange={e => onChangeTabName(tab?.id, e.target.value)}
                onBlur={() => setEditingTabId(null)}
              />
            );
          }

          return (
            <TabOption
              onClick={() => onSelectTab(tab?.id)}
              key={tab?.id}
              isSelected={selectedId === tab?.id}
            >
              {tabContent}
              <StyledTrashIcon
                style={isEditable ? {} : { visibility: "hidden" }}
                onClick={e => {
                  e.stopPropagation();
                  onRemoveTab(tab?.id);
                }}
              />
            </TabOption>
          );
        })}
      </TabsContainer>
    </Container>
  );
};

export default NavWithTabsNew;
