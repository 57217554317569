import { KeyboardArrowDown } from "@material-ui/icons";
import { TickIcon } from "components/ui/Icons";
import TooltipClick from "components/ui/TooltipClick";
import TooltipClickNew from "components/ui/TooltipClickNew";
import styled from "styled-components";

const TickedIcon = styled(TickIcon)`
  width: 12px;
  height: 12px;
  fill: ${props => props.theme.color.primary};
`;

const UntickedCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const Option = styled.div`
  line-height: 1.2;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 10px;
  align-items: center;
  padding: 12px 18px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const OptionsContainer = styled.div`
  box-shadow: ${props => props.theme.shadow};
  border-radius: 10px;
  // first of type
  ${Option}:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  ${Option}:last-of-type {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  max-height: 200px;
  overflow: auto;
`;

const SelectCustom = styled.div`
  cursor: pointer;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto 1fr;
  gap: 4px;
  padding: 8px;
  width: 130px;
  border-radius: 10px;

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    height: 14px;
    width: 14px;
    fill: #000000;
  }
`;

const GreyText = styled.div`
  color: ${props => props.theme.color.closer2};
  font-size: 12px;
  margin-left: 40px;
  font-weight: 400;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

const OptionLabel = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const OptionIcon = styled.div`
  grid-row: span 2;
  display: flex;
  align-content: start;

  svg {
    height: 14px;
    width: 14px;
    fill: #000000;
  }
`;

const CustomSelect = ({
  value = "",
  onChange = e => {},
  options,
  style,
  className = "",
}) => {
  const selectedValue =
    options?.find(option => option?.value === value) || options?.[0]?.value;

  return (
    <TooltipClickNew
      tipContent={
        <OptionsContainer>
          {options?.map(option => {
            return (
              <Option
                key={option?.value}
                onClick={() => {
                  onChange({ target: { value: option?.value } });
                }}
              >
                <OptionIcon>{option?.icon}</OptionIcon>
                <OptionLabel>{option?.label}</OptionLabel>
                <div
                  style={{
                    justifySelf: "end",
                  }}
                >
                  {value === option?.value ? (
                    <TickedIcon style={{ gridRow: "span 2" }} />
                  ) : (
                    <UntickedCircle />
                  )}
                </div>
                <div>{option?.description}</div>
              </Option>
            );
          })}
        </OptionsContainer>
      }
    >
      <SelectCustom style={style} className={className}>
        {selectedValue?.icon || <div />} {selectedValue?.label}{" "}
        <KeyboardArrowDown style={{ justifySelf: "end" }} />
      </SelectCustom>
    </TooltipClickNew>
  );
};

export default CustomSelect;
