export const OPENAPI_CONFIG = {
  openapi: "3.0.0",
  info: {
    description: "Definitions for Boltzbit's product API's",
    title: "Boltzbit API",
    version: "1.0.0",
  },
  servers: [
    {
      url: "https://flow.boltzbit.com/bz-api",
    },
    {
      url: "https://ocr.boltztest.com/bz-api",
    },
  ],
  security: [
    {
      BearerAuth: [],
    },
  ],
  paths: {
    "/v1/files/bulk-upload": {
      description: "Bulk upload files",
      // https://redocly.com/docs-legacy/api-reference-docs/specification-extensions/x-code-samples
      "x-codeSamples": [
        {
          lang: "cURL",
          label: "POST",
          source:
            "curl --request POST \\\n--url 'https://data.apiexample.com/api/example/batch_query/json?format=json' \\\n--header 'content-type: application/octet-stream: '",
        },
        {
          lang: "python",
          label: "Upload a file",
          source: 'print("upload")\n',
        },
        {
          lang: "python",
          label: "Check a file",
          source: 'print("check")\nprint("ok")',
        },
      ],
      post: {
        requestBody: {
          content: {
            "multipart/form-data": {
              schema: {
                $ref: "#/components/schemas/_v1_files_bulk_upload_post_request",
              },
            },
          },
          required: true,
        },
        responses: {
          200: {
            content: {
              "application/json": {
                schema: {
                  items: {
                    $ref: "#/components/schemas/FileResource",
                  },
                  type: "array",
                },
              },
            },
            description: "Success",
          },
          default: {
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/Error",
                },
              },
            },
            description: "Error response",
          },
        },
        summary: "Bulk upload files",
      },
    },
    "/v1/example/endpoint": {
      description: "Example endpoint",
      // https://redocly.com/docs-legacy/api-reference-docs/specification-extensions/x-code-samples
      "x-codeSamples": [
        {
          lang: "cURL",
          label: "POST",
          source:
            "curl --request POST \\\n--url 'https://data.apiexample.com/api/example/batch_query/json?format=json' \\\n--header 'content-type: application/octet-stream: '",
        },
        {
          lang: "python",
          label: "Upload a file",
          source: 'print("upload")\n',
        },
        {
          lang: "python",
          label: "Check a file",
          source: 'print("check")\nprint("ok")',
        },
      ],
    },
  },
  components: {
    responses: {
      ErrorResp: {
        content: {
          "application/json": {
            schema: {
              $ref: "#/components/schemas/Error",
            },
          },
        },
        description: "Error response",
      },
    },
    schemas: {
      FileResource: {
        example: {
          originalFileName: "BMW Annual Report 2020.pdf",
          createdAt: "2000-01-23T04:56:07.000Z",
          fileName: "BMW Annual Report 2020.pdf",
          pageResults: [
            {
              pageNumber: 0,
              file: "file",
              progress: 6,
              id: "id",
              fileId: "fileId",
              status: "status",
            },
            {
              pageNumber: 0,
              file: "file",
              progress: 6,
              id: "id",
              fileId: "fileId",
              status: "status",
            },
          ],
          numberOfPages: 60,
          sizeKb: 30,
          progress: 100,
          id: "630372a598b2430fc05acc13",
          sharedWith: [
            {
              userGroupId: "group_001",
              accessLevel: "OWNER",
            },
            {
              userGroupId: "group_001",
              accessLevel: "OWNER",
            },
          ],
          status: "DONE",
        },
        properties: {
          fileName: {
            example: "BMW Annual Report 2020.pdf",
            type: "string",
          },
          originalFileName: {
            example: "BMW Annual Report 2020.pdf",
            type: "string",
          },
          numberOfPages: {
            example: 60,
            type: "integer",
          },
          sizeKb: {
            example: 30,
            type: "integer",
          },
          status: {
            example: "DONE",
            type: "string",
          },
          progress: {
            example: 100,
            type: "integer",
          },
          id: {
            example: "630372a598b2430fc05acc13",
            type: "string",
          },
          createdAt: {
            format: "date-time",
            type: "string",
          },
          pageResults: {
            items: {
              $ref: "#/components/schemas/PageResult",
            },
            type: "array",
          },
          sharedWith: {
            items: {
              $ref: "#/components/schemas/UserAccess",
            },
            type: "array",
          },
        },
        required: [
          "createdAt",
          "fileName",
          "id",
          "numberOfPages",
          "originalFileName",
          "pageResults",
          "progress",
          "sharedWith",
          "sizeKb",
          "status",
        ],
        type: "object",
      },
      Error: {
        example: {
          details: "details",
          message: "message",
        },
        properties: {
          message: {
            type: "string",
          },
          details: {
            type: "string",
          },
        },
        required: ["message"],
        type: "object",
      },
      ChatQueryResp: {
        example: {
          answerText: "Sure: 2 + 2 = 4.",
        },
        properties: {
          answerText: {
            example: "Sure: 2 + 2 = 4.",
            type: "string",
          },
        },
        required: ["answerText"],
        type: "object",
      },
      ExtractionQueryResp: {
        example: {
          answers: [
            {
              answerText: "The cost of the item is $2000.",
              references: [
                {
                  pageNumber: 5,
                  referenceText: "$2000",
                  answerTextSpan: [5, 8],
                  sourceIndex: 0,
                  fileID: "630372b298b2430fc05acc3a",
                },
                {
                  pageNumber: 5,
                  referenceText: "$2000",
                  answerTextSpan: [5, 8],
                  sourceIndex: 0,
                  fileID: "630372b298b2430fc05acc3a",
                },
              ],
            },
            {
              answerText: "The cost of the item is $2000.",
              references: [
                {
                  pageNumber: 5,
                  referenceText: "$2000",
                  answerTextSpan: [5, 8],
                  sourceIndex: 0,
                  fileID: "630372b298b2430fc05acc3a",
                },
                {
                  pageNumber: 5,
                  referenceText: "$2000",
                  answerTextSpan: [5, 8],
                  sourceIndex: 0,
                  fileID: "630372b298b2430fc05acc3a",
                },
              ],
            },
          ],
        },
        properties: {
          answers: {
            items: {
              $ref: "#/components/schemas/ExtractionQueryResp_answers_inner",
            },
            type: "array",
          },
        },
        type: "object",
      },
      SigmaRecordV2: {
        example: {
          createdAt: "2000-01-23T04:56:07.000Z",
          createdBy: "createdBy",
          emailPdfFileId: "emailPdfFileId",
          id: 0,
          extractionJobId: "extractionJobId",
          fields: {
            key: {
              reference: "",
              rawValue: "rawValue",
              value: "",
            },
          },
          pipelineId: "pipelineId",
          tableName: "tableName",
          updatedAt: "2000-01-23T04:56:07.000Z",
          status: "status",
        },
        properties: {
          id: {
            type: "integer",
          },
          pipelineId: {
            type: "string",
          },
          tableName: {
            type: "string",
          },
          createdAt: {
            format: "date-time",
            type: "string",
          },
          updatedAt: {
            format: "date-time",
            type: "string",
          },
          createdBy: {
            type: "string",
          },
          status: {
            type: "string",
          },
          emailPdfFileId: {
            type: "string",
          },
          extractionJobId: {
            type: "string",
          },
          fields: {
            additionalProperties: {
              $ref: "#/components/schemas/SigmaRecordV2_fields_value",
            },
            type: "object",
          },
        },
        required: [
          "createdAt",
          "createdBy",
          "fields",
          "id",
          "pipelineId",
          "tableName",
          "updatedAt",
        ],
        type: "object",
      },
      PageResult: {
        example: {
          pageNumber: 0,
          file: "file",
          progress: 6,
          id: "id",
          fileId: "fileId",
          status: "status",
        },
        properties: {
          id: {
            description: "The unique identifier for the File Page Result",
            type: "string",
          },
          pageNumber: {
            description: "The page number of the file",
            type: "integer",
          },
          fileId: {
            description: "The ID of the file",
            type: "string",
          },
          file: {
            description: "The name of the file",
            type: "string",
          },
          status: {
            description: "The status of the file",
            type: "string",
          },
          progress: {
            description: "The progress of the file processing",
            type: "integer",
          },
        },
        required: ["file", "fileId", "id", "pageNumber", "progress", "status"],
        type: "object",
      },
      UserAccess: {
        example: {
          userGroupId: "group_001",
          accessLevel: "OWNER",
        },
        properties: {
          userGroupId: {
            example: "group_001",
            type: "string",
          },
          accessLevel: {
            example: "OWNER",
            type: "string",
          },
        },
        required: ["accessLevel", "userGroupId"],
        type: "object",
      },
      _v1_files_bulk_upload_post_request: {
        properties: {
          files: {
            items: {
              format: "binary",
              type: "string",
            },
            type: "array",
          },
        },
        required: ["files"],
        type: "object",
      },
      _v1_ai_chat_queries_post_request: {
        properties: {
          context: {
            type: "string",
          },
          prompt: {
            type: "string",
          },
        },
        required: ["prompt"],
        type: "object",
      },
      _v1_ai_extraction_queries_post_request_sources_inner: {
        properties: {
          type: {
            enum: ["FILE", "TEXT"],
            example: "FILE",
            type: "string",
          },
          fileID: {
            example: "630372b298b2430fc05acc3a",
            type: "string",
          },
          text: {
            example: "Some literal text",
            type: "string",
          },
        },
        type: "object",
      },
      _v1_ai_extraction_queries_post_request_queries_inner: {
        example: {
          prompt: "What is the name of the person?",
        },
        properties: {
          prompt: {
            type: "string",
          },
        },
        type: "object",
      },
      _v1_ai_extraction_queries_post_request: {
        properties: {
          sources: {
            items: {
              $ref: "#/components/schemas/_v1_ai_extraction_queries_post_request_sources_inner",
            },
            type: "array",
          },
          queries: {
            items: {
              $ref: "#/components/schemas/_v1_ai_extraction_queries_post_request_queries_inner",
            },
            type: "array",
          },
        },
        type: "object",
      },
      _ml__model_name__get_200_response_model_config: {
        example: {
          hidden_size: 1024,
          n_layers: 12,
        },
        properties: {
          n_layers: {
            example: 12,
            type: "integer",
          },
          hidden_size: {
            example: 1024,
            type: "integer",
          },
        },
        type: "object",
      },
      _ml__model_name__get_200_response: {
        example: {
          model_config: {
            hidden_size: 1024,
            n_layers: 12,
          },
          model_name: "model_20240801",
        },
        properties: {
          model_name: {
            example: "model_20240801",
            type: "string",
          },
          model_config: {
            $ref: "#/components/schemas/_ml__model_name__get_200_response_model_config",
          },
        },
        type: "object",
      },
      _ml_train_post_request_training_config: {
        properties: {
          learning_rate: {
            example: 0.00001,
            format: "float",
            type: "number",
          },
          n_epoch: {
            example: 2,
            type: "integer",
          },
        },
        type: "object",
      },
      _ml_train_post_request: {
        properties: {
          model_name: {
            example: "model_20240801",
            type: "string",
          },
          model_config: {
            $ref: "#/components/schemas/_ml__model_name__get_200_response_model_config",
          },
          data_sources: {
            example: ["model_20240911", "model_20240912"],
            items: {
              type: "string",
            },
            type: "array",
          },
          training_config: {
            $ref: "#/components/schemas/_ml_train_post_request_training_config",
          },
          save_model_name: {
            example: "model_20241001",
            type: "string",
          },
        },
        type: "object",
      },
      _ml_train_post_200_response: {
        example: {
          training_job_id: "train_20241001_00001",
          status: "QUEUED",
        },
        properties: {
          training_job_id: {
            example: "train_20241001_00001",
            type: "string",
          },
          status: {
            example: "QUEUED",
            type: "string",
          },
        },
        type: "object",
      },
      _ml_train__training_job_id__logs_get_200_response_log_files: {
        example: {
          loss: 1.9206640625,
          epoch: 1,
          step: 100,
          losses: [1.9206640625, 16.9525, 16.95125],
          epoch_elapsed_time: 18.8728985786438,
          total_elapsed_time: 18.87290048599243,
        },
        properties: {
          epoch: {
            example: 1,
            type: "integer",
          },
          step: {
            example: 100,
            type: "integer",
          },
          loss: {
            example: 1.9206640625,
            type: "number",
          },
          losses: {
            example: [1.9206640625, 16.9525, 16.95125],
            items: {
              type: "number",
            },
            type: "array",
          },
          epoch_elapsed_time: {
            example: 18.8728985786438,
            format: "float",
            type: "number",
          },
          total_elapsed_time: {
            example: 18.87290048599243,
            format: "float",
            type: "number",
          },
        },
        type: "object",
      },
      _ml_train__training_job_id__logs_get_200_response: {
        example: {
          log_files: {
            loss: 1.9206640625,
            epoch: 1,
            step: 100,
            losses: [1.9206640625, 16.9525, 16.95125],
            epoch_elapsed_time: 18.8728985786438,
            total_elapsed_time: 18.87290048599243,
          },
        },
        properties: {
          log_files: {
            $ref: "#/components/schemas/_ml_train__training_job_id__logs_get_200_response_log_files",
          },
        },
        type: "object",
      },
      ExtractionQueryResp_answers_inner_references_inner: {
        example: {
          pageNumber: 5,
          referenceText: "$2000",
          answerTextSpan: [5, 8],
          sourceIndex: 0,
          fileID: "630372b298b2430fc05acc3a",
        },
        properties: {
          sourceIndex: {
            example: 0,
            type: "integer",
          },
          referenceText: {
            example: "$2000",
            type: "string",
          },
          fileID: {
            example: "630372b298b2430fc05acc3a",
            type: "string",
          },
          pageNumber: {
            example: 5,
            type: "integer",
          },
          answerTextSpan: {
            example: [5, 8],
            items: {
              type: "integer",
            },
            type: "array",
          },
        },
        type: "object",
      },
      ExtractionQueryResp_answers_inner: {
        example: {
          answerText: "The cost of the item is $2000.",
          references: [
            {
              pageNumber: 5,
              referenceText: "$2000",
              answerTextSpan: [5, 8],
              sourceIndex: 0,
              fileID: "630372b298b2430fc05acc3a",
            },
            {
              pageNumber: 5,
              referenceText: "$2000",
              answerTextSpan: [5, 8],
              sourceIndex: 0,
              fileID: "630372b298b2430fc05acc3a",
            },
          ],
        },
        properties: {
          answerText: {
            example: "The cost of the item is $2000.",
            type: "string",
          },
          references: {
            items: {
              $ref: "#/components/schemas/ExtractionQueryResp_answers_inner_references_inner",
            },
            type: "array",
          },
        },
        type: "object",
      },
      SigmaRecordV2_fields_value: {
        example: {
          reference: "",
          rawValue: "rawValue",
          value: "",
        },
        properties: {
          value: {},
          reference: {},
          rawValue: {
            type: "string",
          },
        },
        required: ["reference", "value"],
        type: "object",
      },
    },
    securitySchemes: {
      BearerAuth: {
        bearerFormat: "JWT",
        scheme: "bearer",
        type: "http",
      },
    },
  },
};
