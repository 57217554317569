import {
  deleteAppTemplate,
  getAppTemplates,
} from "api/backend/appTemplatesEndpoints";
import { postPipelineConfigFromHubspot } from "api/backend/chatServiceEndpoints";
import { DocSearchIcon } from "components/IconsNew";
import { Gap } from "components/Layout";
import LayoutNew from "components/LayoutNew";
import ButtonWord from "components/ui/ButtonWord";
import { TrashIcon } from "components/ui/Icons";
import { te } from "date-fns/locale";
import { COLOR1, COLOR2 } from "pages/login-v2";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  padding: 32px;
  padding-top: 60px;
  height: 100%;
  display: grid;
  align-content: start;
  gap: 15px;
  background: linear-gradient(180deg, #f3f5f7 0%, #f3f5f7 100%);
  ${props =>
    props.isDisabled &&
    "pointer-events: none; opacity: 0.5;"}/* background-color: #f3f5f7; */
`;

const IconAndName = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    fill: black;
    height: 14px;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const BoldDiv = styled.span`
  font-weight: 600;
`;

const Label = styled.label`
  padding: 0 12px;
  gap: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  background-color: #141718;
  color: #ffffff;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  :hover {
    color: #eaeaea;
  }

  ${props =>
    props?.disabled &&
    `pointer-events: none;
    background-color: #e0e0e0;
  `}
`;

const StyledInput = styled.input`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 8px 14px;
  font-weight: 500;
  :disabled {
    opacity: 0.5;
  }
`;

const TemplateTrashIcon = styled(TrashIcon)`
  height: 14px;
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;
  :hover {
    opacity: 1 !important;
  }
`;

const TemplateCard = styled.div`
  width: 245px;
  height: 136px;
  background-color: white;
  padding: 12px;
  cursor: pointer;
  border-radius: 10px;
  font-weight: 600;
  position: relative;
  :hover {
    ${TemplateTrashIcon} {
      opacity: 0.5;
    }

    box-shadow: 0px 1px 7px 1px #00000040;
    background: linear-gradient(176.39deg, ${COLOR2} 39.89%, ${COLOR1} 105.35%);
    svg {
      fill: white;
    }
    color: white;
    * {
      color: white;
    }
    td,
    th {
      background-color: transparent;
    }
  }

  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const TemplateDescription = styled.div`
  font-weight: 400;
  padding-top: 10px;
  color: #afb6b9;
`;

const CreateAppPage = () => {
  const navigate = useNavigate();

  const [templates, setTemplates] = useState([]);
  const [isUploadingCsv, setIsUploadingCsv] = useState(false);
  const [error, setError] = useState(null);
  const [isCreatingApp, setIsCreatingApp] = useState(false);

  useEffect(() => {
    doPopulateTemplates();
  }, []);

  const doPopulateTemplates = async () => {
    const { data } = await getAppTemplates();
    setTemplates(data);
  };

  const onChangeCsvFile = () => {};

  const onClickTemplate = async template => {
    setError(null);
    if (template?.id === "hubspot") {
      setIsCreatingApp(true);
      const { error } = await postPipelineConfigFromHubspot();
      setIsCreatingApp(false);
      setError(error);
      if (error) {
        return;
      }

      navigate(
        '/?sidebar=%7B"type"%3A"pipelines"%2C"filterTypes"%3A%5Bnull%5D%7D'
      );
      return;
    }

    let url = `/apps/new/settings`;
    if (template?.id !== "blank") {
      url += `?templateId=${template?.id}`;
    }
    navigate(url);
  };

  const renderTemplateCard = template => {
    const templateContent = JSON.parse(template?.template || "{}");

    if (template?.id === "hubspot") {
      return (
        <TemplateCard
          isDisabled={isCreatingApp}
          key={template.id}
          onClick={() => onClickTemplate(template)}
        >
          <IconAndName>
            <DocSearchIcon />
            From HubSpot
          </IconAndName>
          <TemplateDescription>Import your HubSpot data</TemplateDescription>
        </TemplateCard>
      );
    }

    return (
      <TemplateCard key={template.id} onClick={() => onClickTemplate(template)}>
        <IconAndName>
          <DocSearchIcon />
          {template.appName || "Blank"}
        </IconAndName>
        <TemplateDescription>
          {template?.id === "blank"
            ? "A clean slate"
            : templateContent?.description}
        </TemplateDescription>
        <TemplateTrashIcon
          style={{ display: template?.id === "blank" ? "none" : "block" }}
          onClick={async e => {
            e.stopPropagation();
            e.preventDefault();
            await deleteAppTemplate(template?.id, {
              isPublic: template?.createdBy === "PUBLIC",
            });
            setTemplates(templates.filter(t => t.id !== template?.id));
          }}
        />
      </TemplateCard>
    );
  };

  let publicTemplates = templates?.filter(
    template => template?.createdBy === "PUBLIC"
  );
  publicTemplates = [{ id: "blank" }, ...publicTemplates, { id: "hubspot" }];
  const privateTemplates = templates?.filter(
    template => template?.createdBy !== "PUBLIC"
  );

  return (
    <LayoutNew>
      <Container>
        <Title>Choose template</Title>
        <Gap height="10px" />
        <div
          style={{
            display: "grid",
            justifyContent: "start",
            gap: 10,
            gridTemplateColumns: "auto auto auto auto",
          }}
        >
          <BoldDiv style={{ gridColumn: "1 / -1" }}>Basic Templates</BoldDiv>
          {publicTemplates.map(renderTemplateCard)}
          <BoldDiv style={{ marginTop: 40, gridColumn: "1 / -1" }}>
            Your Templates
          </BoldDiv>
          {!privateTemplates?.length && (
            <div style={{ gridColumn: "1 / -1", opacity: 0.5, height: 100 }}>
              No templates
            </div>
          )}
          {privateTemplates.map(renderTemplateCard)}
          <BoldDiv style={{ marginTop: 40, gridColumn: "1 / -1" }}>
            From prompt
          </BoldDiv>
          <div style={{ display: "flex", gap: 10, gridColumn: "1 / -1" }}>
            <StyledInput
              style={{ width: 600, backgroundColor: "white" }}
              placeholder="An app to keep track of my customers"
            />
            <ButtonWord isPrimary disabled>
              Generate
            </ButtonWord>
          </div>
          {/* <BoldDiv style={{ marginTop: 40, gridColumn: "1 / -1" }}>
            From CSV file
          </BoldDiv>
          <div style={{ display: "flex", gap: 10, gridColumn: "1 / -1" }}>
            <Label disabled={isUploadingCsv}>
              Upload
              <input
                type="file"
                style={{ display: "none" }}
                onChange={onChangeCsvFile}
              />
            </Label>
          </div> */}
        </div>
        {error && <div style={{ width: 400 }}>{error?.message}</div>}
      </Container>
    </LayoutNew>
  );
};

export default CreateAppPage;
