import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const HOME_URL = "/flow-home";
// const HOME_URL = "/apps-new/677fc2af24f19e8f47291250/home";

const LandingPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(HOME_URL);
  }, []);

  return null;
};

export default LandingPage;
