import { OpenWith } from "@material-ui/icons";
import { CrossIcon, PlusIcon } from "components/ui/Icons";
import { cloneDeep, isEmpty, isNil, set } from "lodash";
import { Children, useRef, useEffect, useState } from "react";
import styled from "styled-components";

import { isTruthy, uuidv4 } from "utils/common";

const SQUARE_SIZE = 20;
const NUM_ROWS = 100;
const NUM_COLS = 100;

const INITIAL_COLS = 2;
const INITIAL_W = 12;
const INITIAL_H = 7;

const isRectOverEmptyCell = (rect = { x: null, y: null }, layout = {}) => {
  if (isNil(rect?.x) || isNil(rect?.y)) {
    return false;
  }

  const isRectOverId = id => {
    const { x, y, w, h } = layout[id];
    const isOverX = rect?.x >= x - 1 && rect?.x <= x + w;
    const isOverY = rect?.y >= y - 1 && rect?.y <= y + h;
    return isOverX && isOverY;
  };

  const ids = Object.keys(layout);
  const isOverACell = ids.some(isRectOverId);

  return !isOverACell;
};

const placeItems = (keys, layout) => {
  const newLayout = cloneDeep(layout);

  let x = 1;
  let y = 1;
  let w = INITIAL_W;
  let h = INITIAL_H;

  keys.filter(isTruthy).forEach(key => {
    newLayout[key] = { x, y, w, h };
    x += w + 1;
    if (x >= INITIAL_COLS * w) {
      x = 1;
      y += h + 1;
    }
  });

  return newLayout;
};

const ensureMargins = (layout = {}) => {
  const newLayout = {};
  Object.entries(layout).forEach(([id, box]) => {
    const { x, y, w, h } = box;
    newLayout[id] = {
      x: Math.max(1, x),
      y: Math.max(0, y),
      w,
      h,
    };
  });

  return newLayout;
};

const getNearestCoords = (e, containerRef) => {
  const containerRect = containerRef.current.getBoundingClientRect();
  const { scrollLeft, scrollTop } = containerRef.current;

  const offsetX = e.clientX - containerRect.x + scrollLeft;
  const offsetY = e.clientY - containerRect.y - SQUARE_SIZE * 0.5 + scrollTop;

  const nearestX = Math.round(offsetX / SQUARE_SIZE);
  const nearestY = Math.round(offsetY / SQUARE_SIZE);

  return { nearestX, nearestY };
};

const Container = styled.div`
  white-space: pre-wrap;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 1;

  ::-webkit-scrollbar {
    display: none;
  }

  background-size: ${SQUARE_SIZE}px ${SQUARE_SIZE}px;
  background-image: linear-gradient(
      to right,
      ${props => (props.isBgVisible ? "#eaeaea" : "transparent")} 1px,
      transparent 1px
    ),
    linear-gradient(
      to bottom,
      ${props => (props.isBgVisible ? "#eaeaea" : "transparent")} 1px,
      transparent 1px
    );
`;

const MoveHandle = styled.div`
  position: absolute;
  top: 0;
  left: -${SQUARE_SIZE}px;
  width: ${SQUARE_SIZE}px;
  height: ${SQUARE_SIZE}px;
  cursor: move;
  opacity: 0;
  transition: opacity 0.2s;
  border: 1px dashed #b8b8b8;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: #d8d8d8;
  }

  svg {
    color: #b8b8b8;
  }

  ${props => props.isEditingDisabled && "display: none;"}
`;

const PlusHandle = styled.div`
  position: absolute;
  transform: translateY(-70%);
  top: 50%;
  right: -4px;
  padding: 4px;
  border-radius: 50%;
  :hover {
    background-color: #d8d8d8;
  }
  ${props => props.isEditingDisabled && "display: none;"}
`;

const CrossHandle = styled.div`
  position: absolute;
  opacity: 0;
  top: 0;
  right: -${SQUARE_SIZE}px;
  padding: 4px;
  border-radius: 50%;
  :hover {
    background-color: #d8d8d8;
  }
  ${props => props.isEditingDisabled && "display: none;"}
`;

const Edge = styled.svg``;

const ResizeHandle = styled.div`
  position: absolute;
  bottom: -${SQUARE_SIZE * 0.5}px;
  right: -${SQUARE_SIZE * 0.5}px;
  width: ${SQUARE_SIZE * 0.5}px;
  height: ${SQUARE_SIZE * 0.5}px;
  cursor: nwse-resize;
  opacity: 0;
  transition: opacity 0.2s;
  border: 1px dashed #b8b8b8;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: #d8d8d8;
  }

  svg {
    color: #b8b8b8;
  }

  ${props => props.isEditingDisabled && "display: none;"}
`;

const HoverRect = styled.div`
  position: absolute;
  background-color: #1e47ff5c;
  width: ${SQUARE_SIZE}px;
  height: ${SQUARE_SIZE}px;
  opacity: 0.2;
  transition: opacity 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DraggableItem = styled.div`
  position: absolute;
  z-index: 1;
  :hover {
    ${MoveHandle} {
      opacity: 1;
    }
    ${ResizeHandle} {
      opacity: 1;
    }
    ${CrossHandle} {
      opacity: 1;
    }
  }
`;

const ItemContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  border: 1px dashed ${props => (props.isDragging ? "#b8b8b8" : "transparent")};
  :hover {
    border: 1px dashed #e3e3e3;
    ${props => props.isEditingDisabled && "border: 1px solid transparent;"}
  }
`;

const W = 6;
const H = 5;

const GridDraggableConnect = ({
  initialLayout = {},
  initialEdges = [],
  onDragEnd = newLayout => {},
  onClickEmptyCell = () => {},
  children = [],
  className = "",
  style = {},
  isEditingDisabled = false,
  onNewKey = () => {},
  onNewEdges = () => {},
  onDeleteKey = () => {},
}) => {
  const containerRef = useRef(null);
  const [layout, setLayout] = useState(initialLayout);
  const [hasLayoutBeenSet, setHasLayoutBeenSet] = useState(false);
  const [hoverRect, setHoverRect] = useState({ x: -1, y: -1 });
  const [scroll, setScroll] = useState({ scrollLeft: 0, scrollTop: 0 });
  const [edges, setEdges] = useState(initialEdges);

  const keys = Children?.map(children, child => child?.key);

  useEffect(() => {
    // if (hasLayoutBeenSet) {
    //   return;
    // }

    // if (isEmpty(initialLayout)) {
    //   const newLayout = placeItems(keys, {});
    //   setLayout(newLayout);
    //   setHasLayoutBeenSet(true);
    //   return;
    // }

    // setLayout(ensureMargins(initialLayout));
    setLayout(initialLayout);
    setEdges(initialEdges);
    setHasLayoutBeenSet(true);
  }, [
    JSON.stringify(initialLayout),
    JSON.stringify(keys),
    JSON.stringify(initialEdges),
  ]);

  const addChildOnTheSide = key => {
    const newLayout = cloneDeep(layout);
    const { x, y, w, h } = layout[key];
    const newKey = uuidv4();
    newLayout[newKey] = { x: x + w, y, w, h };
    onNewKey(newKey);
    setEdges([...edges, { source: key, target: newKey }]);
    onNewEdges([...edges, { source: key, target: newKey }]);
    setLayout(newLayout);
    onDragEnd(newLayout);
  };

  const [keyBeingMoved, setKeyBeingMoved] = useState("");
  const [keyBeingResized, setKeyBeingResized] = useState("");

  const isDragging = keyBeingMoved || keyBeingResized;

  return (
    <Container
      className={className}
      onWheel={() => {
        const { scrollLeft, scrollTop } = containerRef.current;
        setScroll({ scrollLeft, scrollTop });
      }}
      isBgVisible={!isEditingDisabled}
      style={{
        ...style,
        backgroundPosition: `-${scroll.scrollLeft}px -${scroll.scrollTop}px`,
      }}
      ref={containerRef}
      onDragOver={e => {
        e.preventDefault();
        const { nearestX, nearestY } = getNearestCoords(e, containerRef);
        const newLayout = cloneDeep(layout);

        if (keyBeingMoved) {
          newLayout[keyBeingMoved].x = nearestX;
          newLayout[keyBeingMoved].y = nearestY;
        }

        if (keyBeingResized) {
          newLayout[keyBeingResized].w =
            nearestX - newLayout[keyBeingResized].x;
          newLayout[keyBeingResized].h =
            nearestY - newLayout[keyBeingResized].y;
        }

        setLayout(newLayout);
      }}
      onDragEnd={() => {
        onDragEnd(ensureMargins(layout));
        setKeyBeingMoved("");
        setKeyBeingResized("");
      }}
      onMouseMove={e => {
        if (isEditingDisabled) {
          return;
        }

        const { nearestX, nearestY } = getNearestCoords(e, containerRef);
        setHoverRect({
          x: nearestX,
          y: nearestY,
        });
      }}
      onClick={() => {
        if (isEditingDisabled) {
          return;
        }

        if (isRectOverEmptyCell(hoverRect, layout)) {
          onClickEmptyCell(hoverRect);
        }
      }}
    >
      {/* <HoverRect
        style={{
          top: hoverRect?.y * SQUARE_SIZE,
          left: hoverRect?.x * SQUARE_SIZE,
          opacity: isRectOverEmptyCell(hoverRect, layout) ? 0.2 : 0,
          display: isEditingDisabled ? "none" : "flex",
        }}
      >
        +
      </HoverRect> */}
      {Children?.map(children, child => (
        <DraggableItem
          isEditingDisabled={isEditingDisabled}
          key={child?.key}
          style={{
            top: layout?.[child?.key]?.y * SQUARE_SIZE,
            left: layout?.[child?.key]?.x * SQUARE_SIZE,
            // width: layout?.[child?.key]?.w * SQUARE_SIZE,
            // height: layout?.[child?.key]?.h * SQUARE_SIZE,
            width: W * SQUARE_SIZE,
            height: H * SQUARE_SIZE,
          }}
        >
          <MoveHandle
            draggable
            onDragStart={() => setKeyBeingMoved(child?.key)}
            isEditingDisabled={isEditingDisabled}
          >
            <OpenWith
              style={{ height: `${SQUARE_SIZE}px`, width: `${SQUARE_SIZE}px` }}
            />
          </MoveHandle>
          {/* <ResizeHandle
            draggable
            onDragStart={() => setKeyBeingResized(child?.key)}
            isEditingDisabled={isEditingDisabled}
          /> */}
          <ItemContent
            isEditingDisabled={isEditingDisabled}
            isDragging={isDragging}
            // style={{
            //   border: isDragging
            //     ? "1px dashed #b8b8b8"
            //     : "1px dashed transparent",
            // }}
          >
            {child}
          </ItemContent>
          <PlusHandle
            isEditingDisabled={isEditingDisabled}
            onClick={() => addChildOnTheSide(child?.key)}
          >
            <PlusIcon />
          </PlusHandle>
          <CrossHandle
            isEditingDisabled={isEditingDisabled}
            onClick={() => {
              const newLayout = cloneDeep(layout);
              delete newLayout[child?.key];
              setLayout(newLayout);

              const newEdges = edges.filter(
                ({ source, target }) =>
                  source !== child?.key && target !== child?.key
              );
              setEdges(newEdges);
              onNewEdges(newEdges);
              onDeleteKey(child?.key);
            }}
          >
            <CrossIcon height="12px" />
          </CrossHandle>
        </DraggableItem>
      ))}
      {edges?.map(({ source, target }) => {
        const sourceBox = { ...(layout[source] || {}), w: W, h: H };
        const targetBox = { ...(layout[target] || {}), w: W, h: H };

        if (!sourceBox || !targetBox) {
          return null;
        }

        const sourceX =
          sourceBox.x * SQUARE_SIZE + sourceBox.w * SQUARE_SIZE * 0.5;
        const sourceY =
          sourceBox.y * SQUARE_SIZE + sourceBox.h * 0.5 * SQUARE_SIZE;

        const targetX =
          targetBox.x * SQUARE_SIZE + targetBox.w * SQUARE_SIZE * 0.5;
        const targetY =
          targetBox.y * SQUARE_SIZE + targetBox.h * 0.5 * SQUARE_SIZE;

        const width = Math.max(Math.abs(targetX - sourceX), 1);
        const height = Math.max(Math.abs(targetY - sourceY), 1);

        const scaleX = targetX - sourceX > 0 ? 1 : -1;
        const scaleY = targetY - sourceY > 0 ? 1 : -1;

        return (
          <Edge
            key={`${source}-${target}`}
            style={{
              position: "absolute",
              top: sourceY,
              left: sourceX,
              width,
              height,
              transformOrigin: "top left",
              transform: `scale(${scaleX}, ${scaleY})`,
            }}
          >
            <path
              d={`M 0 0 L ${width} ${height}`}
              stroke="grey"
              fill="transparent"
              strokeWidth="1"
            />
          </Edge>
        );
      })}
    </Container>
  );
};

export default GridDraggableConnect;
