import { Gap } from "components/Layout";
import ButtonWord from "components/ui/ButtonWord";
import { PencilIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import { useState } from "react";
import ReactQuill from "react-quill";
import styled from "styled-components";

const ModalContent = styled.div`
  position: relative;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  width: 600px;
  height: 400px;
  overflow: auto;
`;

const Title = styled.div`
  font-weight: 500;
`;

const BottomContainer = styled.div`
  position: absolute;
  bottom: 20px;
  padding-top: 10px;
  width: 560px;
  background-color: white;
  border-top: 1px solid #d8d8d8;
  display: grid;
  justify-content: end;
`;

const QuillContainer = styled.div`
  .ql-editor {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    ${props => props.longTextMinHeightActive && `height: 165px;`}
  }
  .ql-toolbar {
    padding: 0;
  }
`;

const Trigger = styled.div``;

const LongTextEditorModal = ({ value = "" }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClickApply = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Trigger onClick={() => setIsOpen(true)}>
        <PencilIcon />
      </Trigger>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Title>Edit value</Title>
          <Gap />
          <QuillContainer>
            <ReactQuill
              style={{ height: 200 }}
              theme="snow"
              defaultValue={value}
              // onChange={newValue => {
              //   onChange({ target: { value: newValue } });
              // }}
            />
          </QuillContainer>

          <BottomContainer>
            <ButtonWord isPrimary onClick={onClickApply}>
              Apply
            </ButtonWord>
          </BottomContainer>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LongTextEditorModal;
