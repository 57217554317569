import { PlusIcon } from "components/ui/Icons";
import styled from "styled-components";
import { useState } from "react";

import Modal from "components/ui/Modal";
import TextInputSquare from "components/inputs/TextInputSquare";
import SmallButton from "components/ui/SmallButton";
import { useEffect } from "react";
import ErrorMessageModal from "components/ErrorMessageModal";
import {
  getLoggedInUserName,
  postUserGroups,
} from "api/services/authenticationService";
import ButtonIcon from "components/ui/ButtonIcon";
import { postTeam } from "api/backend/authenticationServiceEndpoints";

const PlusButton = styled(PlusIcon)`
  padding: 4px;
  cursor: pointer;
  border-radius: 50%;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
  width: 22px;
  height: 22px;
`;

const ModalContent = styled.div`
  background-color: ${props => props.theme.color.furthest};
  padding: 10px;
  border-radius: 5px;
  display: grid;
  gap: 10px;
  justify-items: start;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const NewFolderText = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

const StyledSmallButton = styled(SmallButton)`
  padding: 4px 20px;
`;

const StyledButtonIcon = styled(ButtonIcon)`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 4px;
  height: auto;
  padding: 6px 14px;
  font-weight: 500;
  font-size: 14px;
`;

const CreateUserGroupModalTrigger = ({
  onCreationSuccess = () => {},
  organizationId = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [userGroupName, setUserGroupName] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setUserGroupName("");
    setIsCreating(false);
  }, [isOpen]);

  const doPostNewTeam = async () => {
    setIsCreating(true);

    const userId = getLoggedInUserName();
    const { error } = await postTeam(
      {},
      { name: userGroupName, organizationId, members: [{ userId }] }
    );

    setError(error);
    setIsCreating(false);

    if (!error) {
      onCreationSuccess();
      setIsOpen(false);
    }
  };

  return (
    <>
      <StyledButtonIcon isActive onClick={() => setIsOpen(true)}>
        <PlusIcon height="10px" />
        <span>New</span>
      </StyledButtonIcon>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isCreating}>
          <NewFolderText>New team</NewFolderText>
          <TextInputSquare
            placeholder="Team name"
            value={userGroupName}
            onNewInput={newName => setUserGroupName(newName)}
          />
          <StyledSmallButton
            isDisabled={!userGroupName}
            onClick={doPostNewTeam}
            value="Create"
          />
        </ModalContent>
      </Modal>
      <ErrorMessageModal
        message={error?.message}
        onDismiss={() => setError(null)}
      />
    </>
  );
};

export default CreateUserGroupModalTrigger;
