import {
  getPipelineConfig,
  postListSigmas,
} from "api/backend/fileSystemEndpoints";
import {
  deleteSequence,
  getEmail,
  getOauthToken,
  getSequence,
  patchSequenceUpdate,
  postSequenceTrigger,
  postSequencesCreate,
} from "api/backend/projectServiceEndpoints";
import ConfigureStepModal from "components/ConfigureStepModal";
import GridDraggableConnect from "components/GridDraggableConnect";
import { CodingIcon, DocSearchIcon } from "components/IconsNew";
import { Gap } from "components/Layout";
import LayoutApp from "components/LayoutApp";
import LayoutAppConfigurable from "components/LayoutAppConfigurable";
import ButtonWord from "components/ui/ButtonWord";
import {
  EmailIcon,
  GearIcon,
  InternetIcon,
  PdfIcon,
  WarningIcon,
  WordIcon,
} from "components/ui/Icons";
import Modal from "components/ui/Modal";
import NavWithTabsNew from "components/ui/NavWithTabsNew";
import { cloneDeep, range, set } from "lodash";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { safeFormat } from "utils/common";

const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  height: calc(100vh - 40px);
`;

const TopContent = styled.div`
  padding: 20px;
`;

const BottomContent = styled.div`
  background-color: white;
  padding: 12px 20px;
  display: flex;
  gap: 20px;
  justify-content: end;
  border-top: 1px solid #ccc;
`;

const StyledInput = styled.input`
  height: 52px;
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 14px;
  font-weight: 500;

  :disabled {
    opacity: 0.6;
  }
`;

const BoldDiv = styled.span`
  font-weight: 600;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  padding: 8px;
`;

const Td = styled.td`
  background-color: white;
  position: relative;
  /* border: 1px solid ${props => props.theme.color.closer1}; */
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  font-weight: 500;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 36px;
  svg {
    fill: black;
    height: 14px;
  }

  :hover {
    background-color: #f3f5f7;
  }
`;

const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;
  padding: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;

  font-weight: 600;
  z-index: 1;

  svg {
    fill: black;
    height: 14px;
  }

  :hover {
    background-color: #f3f5f7;
  }
`;

const Tr = styled.tr`
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 40px;
  overflow: hidden;
`;

const Panel = styled.div`
  border-top: 1px solid #ccc;
  overflow: auto;
  background-color: #f3f5f7;
`;

const ModalTrigger = styled.div`
  opacity: 0;
`;

const StepCard = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  /* background-color: white; */
  border-radius: 12px;
  font-weight: 600;
  /* border: 1px solid #eaeaea; */

  display: grid;
  grid-template-rows: 1fr auto;
  justify-items: center;
  gap: 4px;

  :hover {
    ${ModalTrigger} {
      opacity: 1;
    }
  }
`;

const StepName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 14px;
  line-height: 1.2;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const ActionCard = styled.div`
  font-weight: 400;
  background-color: ${props => props.bgColor || "#ffffff"};
  border-radius: 12px;
  font-size: 12px;
  width: max-content;
  padding: 0 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.isSelected && "border: 1px solid #0191ff;"}
  svg {
    height: 40px;
    width: 40px;
    fill: #242424;
  }
`;

const GreySpan = styled.span`
  font-size: 12px;
  color: #a2a2a2;
`;

const FormFields = styled.div`
  padding: 20px;
`;

const SmallSpan = styled.span`
  font-size: 12px;
`;

const Select = styled.select`
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 14px;
  font-weight: 500;
`;

const TextArea = styled.textarea`
  height: 200px;
  resize: none;
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 14px;
  font-weight: 500;
`;

const TableContainer = styled.div`
  overflow: auto;
  /* border: 1px solid #ccc; */
  position: relative;
  overflow: hidden;
  min-height: 120px;
`;

const EmptyMsg = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  padding-top: 20px;
  transform: translate(-50%, -50%);
  font-weight: 600;
  color: #ccc;
  font-size: 18px;
`;

const STEP_FIELDS = {
  name: "Untitled Step",
  type: "email",
  function: {
    recipients: [],
    template: "",
    subject: "",
  },
  action: {},
  output: [],
  next: [],
};

const STEP_0 = {
  id: "step0",
  name: "Sequence trigger",
  type: "manually-triggered",
  action: {},
  output: [],
  next: [],
};

const STEP_0_INPUT_FIELDS = [
  {
    label: "Sequence trigger",
    type: "select",
    path: "type",
    options: [
      {
        label: "Record created",
        value: "record-created",
      },
      {
        label: "Record updated",
        value: "record-updated",
      },
      {
        label: "Manual trigger",
        value: "manually-triggered",
      },
    ],
  },
];

const STEP_INPUT_FIELDS = [
  {
    type: "text",
    path: "name",
    label: "Name",
  },
  {
    label: "Action type",
    type: "select",
    path: "type",
    options: [
      {
        label: "Send email",
        value: "email",
      },
      {
        label: "Generate word doc",
        value: "generate_word_doc",
      },
    ],
  },
];

const ACTION_TYPE_TO_INPUT_FIELDS = {
  email: [
    {
      type: "number",
      path: "action.offsetMS",
      label: "Delay (milliseconds)",
    },
    { label: "Recipient", type: "text", path: "function.recipients" },
    { label: "Subject", type: "text", path: "function.subject" },
    { label: "Template", type: "textarea", path: "function.template" },
  ],
  generate_word_doc: [
    { label: "Doc Prompt", type: "textarea", path: "function.prompt" },
  ],
};

const FieldInput = ({
  value = "",
  onChangeValue = newValue => {},
  type = "",
  options = [],
}) => {
  if (type === "textarea") {
    return (
      <TextArea value={value} onChange={e => onChangeValue(e.target.value)} />
    );
  }

  if (type === "select") {
    return (
      <Select value={value} onChange={e => onChangeValue(e.target.value)}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    );
  }

  return (
    <StyledInput
      value={value}
      type={type || "text"}
      onChange={e => {
        if (type === "number") {
          onChangeValue(parseInt(e.target.value));
          return;
        }
        onChangeValue(e.target.value);
      }}
    />
  );
};

const updateSequenceNexts = (sequence, edges = []) => {
  const newSequence = cloneDeep(sequence);
  newSequence.steps.forEach(step => {
    step.next = [];
    edges.forEach(edge => {
      if (edge.source === step.id) {
        step.next.push(edge.target);
      }
    });
  });

  return newSequence;
};

const getEdgesFromSequence = sequence => {
  const edges = [];
  sequence?.steps?.forEach(step => {
    step?.next?.forEach(nextId => {
      edges.push({ source: step.id, target: nextId });
    });
  });

  return edges;
};

const ModalContent = styled.div`
  padding: 20px;
  width: 600px;
`;

const IconAndMsg = styled.div`
  display: flex;
  font-weight: 500;
  color: ${props => props.theme.color.in_progress};
  align-items: center;
  svg {
    fill: ${props => props.theme.color.in_progress};
    margin: 0 10px;
  }
`;

const ShortSpan = styled.span`
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const RunModal = ({
  selectedStepId = "",
  sequenceId,
  pipelineConfig = {},
  sequence = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [sigmaRecords, setSigmaRecords] = useState([]);
  const [selectedRecordIds, setSelectedRecordIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRunningSingleStep, setIsRunningSingleStep] = useState(false);
  const [gmailToken, setGmailToken] = useState("");

  const doPopulateGmailToken = async () => {
    const { data } = await getOauthToken("gmail");
    setGmailToken(data || "");
  };

  const doPopulateSigmaRecords = async (pipelineId, tableName) => {
    const { data } = await postListSigmas(
      {
        pipelineId,
        tableName,
      },
      {
        sort: { limit: 10 },
        direction: "DESC",
      }
    );
    setSigmaRecords(data?.items || []);
  };

  useEffect(() => {
    doPopulateGmailToken();
    if (!isOpen) {
      setIsRunningSingleStep(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!pipelineConfig?.id) {
      return;
    }
    const tableId = pipelineConfig?.sourceTables?.[0]?.id;
    doPopulateSigmaRecords(pipelineConfig?.id, tableId);
  }, [pipelineConfig?.id]);

  const doRunSequence = async () => {
    setIsLoading(true);
    let queryParams = {};
    if (isRunningSingleStep) {
      queryParams = {
        stepId: selectedStepId,
      };
    }

    await postSequenceTrigger(sequenceId, queryParams, {
      input: {
        sigmaIds: selectedRecordIds,
        pipelineId: pipelineConfig?.id,
      },
    });
    setIsLoading(false);
    setIsOpen(false);
  };

  const columns = pipelineConfig?.sourceTables?.[0]?.columns || [];

  const stepWithoutRecipient = sequence?.steps?.find(step => {
    if (step?.type !== "email") {
      return false;
    }
    return !step?.function?.recipients?.[0];
  });
  const selectedStep = sequence?.steps?.find(
    step => step?.id === selectedStepId
  );

  let warningContent = null;
  if (stepWithoutRecipient) {
    warningContent = (
      <IconAndMsg>
        <WarningIcon />
        Step "{stepWithoutRecipient?.name}" has no recipient
      </IconAndMsg>
    );
  }
  if (isRunningSingleStep) {
    if (
      selectedStep?.type === "email" &&
      !selectedStep?.function?.recipients?.[0]
    ) {
      warningContent = (
        <IconAndMsg>
          <WarningIcon />
          Step "<ShortSpan>{selectedStep?.name}</ShortSpan>" has no recipient
        </IconAndMsg>
      );
    } else {
      warningContent = null;
    }
  }
  if (!gmailToken) {
    warningContent = (
      <IconAndMsg>
        <WarningIcon />
        <a href="/profile/integrations" target="_blank">
          Connect your Gmail account
        </a>
      </IconAndMsg>
    );
  }

  return (
    <>
      <ButtonWord onClick={() => setIsOpen(true)}>Run</ButtonWord>
      <ButtonWord
        onClick={() => {
          setIsOpen(true);
          setIsRunningSingleStep(true);
        }}
      >
        Run single step
      </ButtonWord>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Title>Select records</Title>
          <div style={{ width: "100%", overflow: "auto", height: 400 }}>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th></th>
                  {columns.map(column => (
                    <Th key={column.id}>
                      {column.name?.replaceAll("_", " / ")}
                    </Th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sigmaRecords.map(record => {
                  const onChange = e => {
                    if (e.target.checked) {
                      setSelectedRecordIds(prev => [...prev, record.id]);
                      return;
                    }
                    setSelectedRecordIds(prev =>
                      prev.filter(id => id !== record.id)
                    );
                  };

                  return (
                    <tr key={record.id}>
                      <Td>
                        <input
                          type="checkbox"
                          checked={selectedRecordIds.includes(record.id)}
                          onChange={onChange}
                        />
                      </Td>
                      {columns.map(column => {
                        let recordValue =
                          record?.fields?.[column.dbName]?.value;
                        if (column?.type === "TABLE") {
                          recordValue = `${recordValue?.length} rows`;
                        }
                        return <Td key={column.id}>{recordValue}</Td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Gap height="20px" />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              alignItems: "center",
            }}
          >
            <ButtonWord
              style={{
                justifySelf: "start",
                whiteSpace: "nowrap",
                maxWidth: 300,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              disabled={isLoading || !gmailToken}
              onClick={doRunSequence}
            >
              {isRunningSingleStep ? (
                <>Run step "{selectedStep?.name}"</>
              ) : (
                "Run sequence"
              )}
            </ButtonWord>
            {warningContent}
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

const IconA = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    height: 14px;
  }
`;

const Outputs = styled.div`
  display: flex;
  gap: 20px;
  width: 500px;
`;

const ColoredDiv = styled.div`
  background-color: #e7e7e7;
  color: #333;
  width: max-content;
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 8px;
`;

export const StepOutput = ({ statusOnly = false, outputRecord }) => {
  const [email, setEmail] = useState(null);

  const output = JSON.parse(outputRecord?.fields?.Output?.value || "{}");

  const wordDocId = output?.generated_word_doc_ids?.[0];
  let emailId = "";
  if (output?.step_type === "email") {
    emailId = output?.result_ids?.[0];
  }

  useEffect(() => {
    if (!emailId) {
      return;
    }
    doPopulateEmail();
    const intervalId = setInterval(() => {
      doPopulateEmail();
    }, 2000);

    return () => clearInterval(intervalId);
  }, [emailId]);

  const doPopulateEmail = async () => {
    const { data } = await getEmail(emailId);
    setEmail(data);
  };

  return (
    <Outputs style={statusOnly ? { width: "auto" } : {}}>
      {email && <ColoredDiv>status: {email?.status}</ColoredDiv>}
      {wordDocId && !statusOnly && (
        <IconA
          href={`/word-docs/${wordDocId}`}
          target="_blank"
          rel="noreferrer"
        >
          <WordIcon /> content
        </IconA>
      )}
    </Outputs>
  );
};

const EXECUTION_COLUMNS = [
  {
    label: "Execution Time",
    name: "Execution Time",
  },
  {
    label: "Step Name",
    name: "Step Name",
  },
  {
    label: "Step Type",
    name: "Step Type",
  },
  {
    label: "Output",
    name: "Output",
  },
];

const getStep = (outputRecord, sequence) => {
  const stepId = outputRecord?.fields?.StepId?.value;
  const step = sequence?.steps?.find(step => step?.id === stepId);
  return step;
};

const STEP_TYPE_TO_ICON = {
  email: <EmailIcon />,
  "manually-triggered": <GearIcon />,
  "document-uploaded": <PdfIcon />,
  generate_word_doc: <WordIcon />,
  "record-created": <DocSearchIcon />,
  "record-updated": <DocSearchIcon />,
  internet: <InternetIcon />,
  code: <CodingIcon />,
};

const STEP_TYPE_TO_COLOR = {
  email: "#fdddab",
  "manually-triggered": "#aa9cfc",
  generate_word_doc: "#65b6f5",
  internet: "#94b5f2",
  code: "#a2f5a2",
};

const TABS = [
  {
    id: "steps",
    tableDisplayName: "Sequence",
  },
  {
    id: "executions",
    tableDisplayName: "Executions",
  },
];

const SequencePageNew = () => {
  const navigate = useNavigate();
  const { pipelineConfigId, sequenceId } = useParams();

  const [pipelineConfig, setPipelineConfig] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [selectedTabId, setSelectedTabId] = useState(TABS?.[0]?.id);

  const [sequence, setSequence] = useState({
    pipelineId: pipelineConfigId,
    name: "Untitled Sequence",
    steps: [STEP_0],
  });
  const [layout, setLayout] = useState({
    step0: { x: 8, y: 1, w: 12, h: 7 },
  });
  const [selectedStepId, setSelectedStepId] = useState("step0");

  const [outputRecords, setOutputRecords] = useState([]);

  const selectedStep = sequence?.steps?.find(
    step => step?.id === selectedStepId
  );

  let basePath = "/apps";
  let LayoutComponent = LayoutApp;
  if (window?.location?.pathname.includes("/apps-new")) {
    basePath = "/apps-new";
    LayoutComponent = LayoutAppConfigurable;
  }

  useEffect(() => {
    if (sequenceId === "new") {
      return;
    }
    doPopulateSequence();
  }, [sequenceId]);

  useEffect(() => {
    doPopulatePipelineConfig();
    doPopulateOutputSigmaRecords(pipelineConfigId);

    const intervalId = setInterval(() => {
      doPopulateOutputSigmaRecords(pipelineConfigId);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [pipelineConfigId]);

  const doPopulateSequence = async () => {
    const { data } = await getSequence(sequenceId);
    setSequence(data || {});
    setLayout(data?.meta?.layout || {});
  };

  const doPopulatePipelineConfig = async () => {
    const { data } = await getPipelineConfig(pipelineConfigId);
    setPipelineConfig(data);
  };

  const doPopulateOutputSigmaRecords = async pipelineId => {
    const { data } = await postListSigmas(
      {
        pipelineId,
        tableName: "source_step_output",
      },
      {
        sort: { limit: 10 },
        direction: "DESC",
      }
    );
    const sequenceOutputRecords =
      data?.items?.filter(
        record => record?.fields?.SeqId?.value === sequenceId
      ) || [];
    setOutputRecords(sequenceOutputRecords);
  };

  const doSaveSequence = async () => {
    setIsSaving(true);
    const payload = {
      ...sequence,
      meta: { layout },
    };
    if (sequenceId === "new") {
      await postSequencesCreate({}, payload);
      setIsSaving(false);
      navigate(`${basePath}/${pipelineConfigId}/sequences`);
      return;
    }

    await patchSequenceUpdate(sequenceId, {}, payload);
    setIsSaving(false);
    navigate(`${basePath}/${pipelineConfigId}/sequences`);
  };

  const doDeleteSequence = async () => {
    setIsSaving(true);
    await deleteSequence(sequenceId);
    navigate(`${basePath}/${pipelineConfigId}/sequences`);
    setIsSaving(false);
  };

  const onChangeSelectedStep = (path, value) => {
    const newSequence = cloneDeep(sequence);
    const newStep = newSequence?.steps?.find(
      step => step?.id === selectedStepId
    );
    set(newStep, path, value);
    setSequence(newSequence);
  };

  let panelContent = (
    <GridDraggableConnect
      style={{ width: "1400px", height: "1400px" }}
      initialLayout={layout}
      initialEdges={getEdgesFromSequence(sequence)}
      onDragEnd={newLayout => setLayout(newLayout)}
      onNewEdges={newEdges => {
        setSequence(prev => updateSequenceNexts(prev, newEdges));
      }}
      onNewKey={key => {
        setSequence(prev => ({
          ...prev,
          steps: [...prev.steps, { ...STEP_FIELDS, id: key }],
        }));
      }}
      onDeleteKey={key => {
        setSequence(prev => ({
          ...prev,
          steps: prev?.steps?.filter((step, i) => step?.id !== key),
        }));
      }}
    >
      {sequence?.steps?.map(step => (
        <StepCard key={step?.id} onClick={() => setSelectedStepId(step?.id)}>
          <ModalTrigger style={{ position: "absolute", top: 2, right: 0 }}>
            <ConfigureStepModal
              selectedStep={selectedStep}
              pipelineConfig={pipelineConfig}
              onChangeSelectedStep={onChangeSelectedStep}
            />
          </ModalTrigger>
          <ActionCard
            isSelected={selectedStepId === step?.id}
            bgColor={STEP_TYPE_TO_COLOR?.[step?.type]}
          >
            {STEP_TYPE_TO_ICON?.[step?.type]}
          </ActionCard>
          <StepName>{step?.name}</StepName>
        </StepCard>
      ))}
    </GridDraggableConnect>
  );
  if (selectedTabId === "executions") {
    panelContent = (
      <TableContainer>
        <table style={{ width: "100%" }}>
          <thead>
            <Tr>
              {EXECUTION_COLUMNS.map(column => (
                <Th key={column.name}>{column.label}</Th>
              ))}
            </Tr>
          </thead>
          <tbody>
            {outputRecords?.length === 0 && <EmptyMsg>No records</EmptyMsg>}
            {outputRecords?.length > 0 &&
              range(0, 4).map(i => {
                const record = outputRecords?.[i];

                if (!record) {
                  return (
                    <Tr key={i}>
                      {EXECUTION_COLUMNS.map(() => (
                        <Td />
                      ))}
                    </Tr>
                  );
                }

                return (
                  <Tr key={`${record?.id}-${i}`}>
                    {EXECUTION_COLUMNS.map(column => {
                      let cellValue = record?.fields?.[column?.dbName]?.value;
                      if (column?.name === "Execution Time") {
                        cellValue = safeFormat(cellValue, "d MMM HH:mm:ss");
                      }
                      if (column?.name === "Step Name") {
                        cellValue = getStep(record, sequence)?.name || "";
                      }
                      if (column?.name === "Output") {
                        cellValue = <StepOutput outputRecord={record} />;
                      }
                      if (column?.name === "Step Type") {
                        cellValue = (
                          <ColoredDiv>
                            {getStep(record, sequence)?.type || ""}
                          </ColoredDiv>
                        );
                      }

                      return <Td key={column.name}>{cellValue}</Td>;
                    })}
                  </Tr>
                );
              })}
          </tbody>
        </table>
      </TableContainer>
    );
  }

  return (
    <LayoutComponent>
      <Container>
        <TopContent>
          <StyledInput
            style={{ width: "400px", fontSize: 24, fontWeight: 600 }}
            value={sequence.name}
            onChange={e => setSequence({ ...sequence, name: e.target.value })}
            placeholder="Sequence Name"
          />
        </TopContent>
        <NavWithTabsNew
          tabs={TABS}
          selectedId={selectedTabId}
          onSelectTab={id => setSelectedTabId(id)}
        />
        <Panel>{panelContent}</Panel>
        <BottomContent>
          {sequenceId !== "new" && (
            <RunModal
              selectedStepId={selectedStepId}
              sequenceId={sequenceId}
              sequence={sequence}
              pipelineConfig={pipelineConfig}
            />
          )}
          {sequenceId !== "new" && (
            <ButtonWord disabled={isSaving} onClick={doDeleteSequence}>
              Delete
            </ButtonWord>
          )}
          <ButtonWord disabled={isSaving} onClick={doSaveSequence}>
            {sequenceId === "new" ? "Create" : "Save"}
          </ButtonWord>
        </BottomContent>
      </Container>
    </LayoutComponent>
  );
};

export default SequencePageNew;
