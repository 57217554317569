import { Editor } from "@monaco-editor/react";
import ButtonWord from "components/ui/ButtonWord";
import Modal from "components/ui/Modal";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const ModalContent = styled.div`
  position: relative;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  width: 800px;
  overflow: auto;
`;

const Title = styled.div`
  font-weight: 500;
`;

const BottomContainer = styled.div`
  padding-top: 10px;
  background-color: white;
  border-top: 1px solid #d8d8d8;
  display: grid;
  justify-content: end;
`;

const StyledEditor = styled(Editor)`
  height: 600px;
`;

const Trigger = styled.div``;

const EditConfigModal = ({
  config = {},
  onClickSave = newStr => {},
  isSaveDisabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [configStr, setConfigStr] = useState(JSON.stringify(config, null, 2));
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setConfigStr(JSON.stringify(config, null, 2));
  }, [JSON.stringify(config)]);

  const onClickApply = async () => {
    setIsSaving(true);
    onClickSave(configStr);
    setIsSaving(false);
  };

  return (
    <>
      <Trigger style={{ justifySelf: "end" }} onClick={() => setIsOpen(true)}>
        <ButtonWord>See code</ButtonWord>
      </Trigger>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Title>Config</Title>
          <StyledEditor
            value={configStr}
            onChange={(value, event) => setConfigStr(value)}
            language="json"
          />
          <BottomContainer>
            <ButtonWord
              disabled={isSaving || isSaveDisabled}
              isPrimary
              onClick={onClickApply}
            >
              Save
            </ButtonWord>
          </BottomContainer>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditConfigModal;
