import { DocSearchIcon } from "components/IconsNew";
import { COLOR1, COLOR2 } from "pages/login-v2";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Td = styled.td`
  padding: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  padding-right: 8px;
`;

const BoldTd = styled(Td)`
  font-weight: 500;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`;

const GroupCard = styled.div`
  position: relative;
  background-color: #ffffff;
  padding: 12px 16px;
  border-radius: 10px;
  height: max-content;
  display: grid;
  align-content: start;
  /* grid-template-columns: auto auto; */
  gap: 4px;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;

  :hover {
    box-shadow: 0px 1px 7px 1px #00000040;
    background: linear-gradient(176.39deg, ${COLOR2} 39.89%, ${COLOR1} 105.35%);
    color: white;
    * {
      color: white;
    }
    td,
    th {
      background-color: transparent;
    }
    svg {
      fill: white;
    }
  }
`;

const GroupTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: start;
  gap: 4px;
  svg {
    fill: black;
    height: 12px;
  }
`;

const ShortName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RecordCard = ({
  pipelineConfig = {},
  record = {},
  tableId = "source0",
}) => {
  const selectedTable = pipelineConfig?.sourceTables?.find(
    table => table?.id === tableId
  );
  const columns = selectedTable?.columns || [];
  const recordNameColumn = columns?.find(column => column?.isNameField);

  const summaryColumns = pipelineConfig?.meta?.summaryColumns || [];

  return (
    <StyledLink to={`/apps/${pipelineConfig?.id}/records/${record?.id}`}>
      <GroupCard>
        <GroupTitle style={{ marginBottom: 10 }}>
          <DocSearchIcon />
          <ShortName>
            {record?.fields?.[recordNameColumn?.dbName]?.value || record.id}
          </ShortName>
        </GroupTitle>
        <div style={{ overflow: "hidden" }}>
          <table>
            <tbody>
              {summaryColumns?.map(dbName => {
                const column = columns?.find(col => col?.dbName === dbName);
                let value = record?.fields?.[dbName]?.value;
                if (typeof value !== "string") {
                  value = JSON.stringify(value);
                }

                return (
                  <tr>
                    <Td>{column?.displayName}</Td>
                    <BoldTd>
                      <ShortName dangerouslySetInnerHTML={{ __html: value }} />
                    </BoldTd>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </GroupCard>
    </StyledLink>
  );
};

export default RecordCard;
